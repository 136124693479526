import React, { useCallback } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Grid, IconButton, Input, Stack, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FirebaseStorage } from "../FirebaseAuth/firebase";

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 80,
  },
  image: {
    width: 180,
    height: 180,
    marginBottom: 24,
    objectFit: "contain",
  },
  uploadInput: {
    display: "none",
  },
  emptyLogo: {
    width: 180,
    height: 180,
    display: "flex",
    fontSize: 24,
    fontWeight: 500,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#aaaaaa",
    color: "#ffffff",
  },
}));

export const ImageUpload = ({ data, setData, imageName, storage }) => {
  const classes = useStyles();

  const handleUpload = useCallback(
    async (image) => {
      try {
        const downloadUrl = await FirebaseStorage.ref(
          `${storage}/${image.name}`
        ).put(image);
        return downloadUrl.ref.getDownloadURL();
      } catch (ex) {
        console.log("ex", ex);
      }
    },
    [storage]
  );

  const handleChangeImage = useCallback(
    async ({ target: { files } }) => {
      if (!!files[0]) {
        const uploadUrl = await handleUpload(files[0]);
        setData({ ...data, [imageName]: uploadUrl });
      }
    },
    [handleUpload, setData, data, imageName]
  );

  return (
    <Grid item xs={12} md={3} className={classes.imageContainer}>
      {data[imageName] ? (
        <Box
          component="img"
          alt={data[imageName]}
          src={data[imageName]}
          className={classes.image}
        />
      ) : (
        <Box className={classes.emptyLogo}>LOGO</Box>
      )}
      <Stack>
        <label htmlFor="contained-button-file">
          <Input
            accept="image/*"
            id="contained-button-file"
            name="image"
            type="file"
            onChange={handleChangeImage}
            className={classes.uploadInput}
          />
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
          >
            <CloudUploadIcon />
            <Box>
              <Typography ml={1}>Update Image</Typography>
            </Box>
          </IconButton>
        </label>
      </Stack>
    </Grid>
  );
};
