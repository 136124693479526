import React, { useState, useEffect } from 'react';
import {
    Button,
    Dialog,
    ListItemText,
    ListItem,
    List,
    Divider,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Checkbox,
    Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Firestore } from '../../components/FirebaseAuth/firebase'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function PlansDialog({ open, selectedPlanId, setSelectedPlanId, onSaved, onClose }) {
    const [plans, setPlans] = useState([]);

    function fetchPlansList() {

        const sitesCollectionsRef = Firestore.collection('plans')

        sitesCollectionsRef.onSnapshot((querySnapshot) => {
            const plansArr = [];
            querySnapshot.forEach((doc) => {
                plansArr.push({ id: doc.id, ...doc.data() })
            });
            setPlans(plansArr);
        });
    }

    useEffect(() => {
        fetchPlansList();
    }, []);

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Plans List
                        </Typography>
                        <Button autoFocus color="inherit" onClick={onSaved}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>
                <List>
                    {plans.map(plan => (
                        <>
                            <ListItem button key={plan.id} onClick={() => setSelectedPlanId(plan.id)}>
                                <Grid container>
                                    <Grid item>
                                        <Checkbox checked={plan.id===selectedPlanId} />
                                    </Grid>
                                    <Grid item>
                                        <ListItemText primary={plan.name} secondary={plan.price} />
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Divider />
                        </>
                    ))}
                </List>
            </Dialog>
        </div>
    );
}
