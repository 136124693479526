import axios from 'axios';

const convertToSlug = (text) => {
    return text
        .toLowerCase()
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, '');
}

const getUrlsWithDomain = (urlsArr, slug) => {
    const urlsWithSlug = urlsArr.map(url => {
        return {
            [Object.keys(url)[0]]: `${url[Object.keys(url)[0]]}`.replace("[site_slug]", `${slug}`)
        }
    });
    return urlsWithSlug;
}

const isKWALLAdmin = (roles) => roles && roles.includes('kwallAdmin');

const isOrgAdmin = (roles) => roles && roles.includes('orgAdmin');

const isOrgUser = (roles) => roles && roles.includes('orgUser');

const isAdmin = (roles) => roles && roles.includes('admin');

const isUser = (roles) => roles && roles.includes('user');

// GET SITE INFORMATION
const getSiteInfo = (site_name_slug) => {

    axios.get('https://terminus.dev-2.staging-preview.com/', {
        params: {
            p: 'RBdTG5DZynuZ2',
            "command": "site:info",
            "site_id": site_name_slug
        }
    }).then(response => {
        return response;
    }).catch(ex => console.log('___ ex'));
}

// ADD DOMAIN
const addDomain = (site_name_slug) => {
    axios.get('https://terminus.dev-2.staging-preview.com/', {
        params: {
            p: 'RBdTG5DZynuZ2',
            "command": "domain:add",
            "site_name_slug": `${site_name_slug}.live`
        }
    }).then(response => {
        return response;
    }).catch(ex => console.log('___ ex'));
}

// REMOVE DOMAIN
const deleteDomain = (site_name_slug) => {

    axios.get('https://terminus.dev-2.staging-preview.com/', {
        params: {
            p: 'RBdTG5DZynuZ2',
            "command": "domain:dns",
            "site_name_slug": `${site_name_slug}.live`
        }
    }).then(response => {
        return response;
    }).catch(ex => console.log('___ ex'));
}

// GET LIST OF DOMAINS
const getDomainList = (site_name_slug) => {

    axios.get('https://terminus.dev-2.staging-preview.com/', {
        params: {
            p: 'RBdTG5DZynuZ2',
            "command": "domain:list",
            "site_name_slug": `${site_name_slug}`
        }
    }).then(response => {
        return response;
    }).catch(ex => console.log('___ ex', ex));
}

const defaultTemplateUrl = 'https://firebasestorage.googleapis.com/v0/b/kwall-customer-portal.appspot.com/o/templates%2FTemplate.png?alt=media&token=fe484527-1312-4534-bfe3-a48faeddeeaa';

export {
    getSiteInfo,
    addDomain,
    deleteDomain,
    getDomainList,
    convertToSlug,
    getUrlsWithDomain,
    defaultTemplateUrl,
    isKWALLAdmin,
    isOrgAdmin,
    isOrgUser,
    isAdmin,
    isUser
};