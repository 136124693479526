import * as React from "react";
import {
  Box,
  CardContent,
  CardMedia,
  Button,
  Typography,
  Grid,
} from "@mui/material";

const SiteCard = ({ site, onSelectPlans, onSelectEdit, onDelete }) => {
  return (
    <Box
      sx={{
        boxShadow: 3,
        bgcolor: "background.paper",
        m: 1,
        p: 1,
        maxWidth: 345,
        marginTop: 7,
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        zIndex: 0,
      }}
    >
      <CardMedia
        style={{
          borderRadius: "50%",
          width: 100,
          position: "relative",
          bottom: 70,
          zIndex: 10,
        }}
        component="img"
        alt="A site template"
        height="100"
        image="https://cdn.pixabay.com/photo/2015/02/05/08/06/macbook-624707_960_720.jpg"
      />
      <CardContent>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          style={{ marginTop: "-72px" }}
        >
          {site.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {site.description}
        </Typography>
      </CardContent>
      {/* <CardActions> */}
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        style={{ padding: 10 }}
      >
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => onSelectPlans(site)}
          >
            Plans
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            onClick={() => onSelectEdit(site.id)}
          >
            Edit
          </Button>
        </Grid>
        <Grid item>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => onDelete(site.id)}
          >
            Delete
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SiteCard;
