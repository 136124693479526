import React from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
} from "@mui/material";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../utils/localStorage";

const DataTable = ({
  columns,
  rows,
  totalRows,
  pageSize,
  page,
  handlePageChane,
  handlePageSizeChange,
  isSubscriptions,
  isSite,
}) => {
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((c, i) => (
                <TableCell key={i} style={c.style}>
                  {c.name}
                </TableCell>
              ))}
              {isSubscriptions && (
                <TableCell style={{ width: "50%" }}>Actions</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows &&
              rows.map((r, i) => (
                <TableRow key={i}>
                  {columns.map((c, k) => (
                    <TableCell key={k}>
                      {" "}
                      {isSite === true && c.field === "name" ? (
                        <Link
                          to={`/account/${currentAccount?.id}/site-details/${r.id}`}
                        >
                          {r[c.field]}
                        </Link>
                      ) : (
                        r[c.field]
                      )}{" "}
                    </TableCell>
                  ))}
                  {isSubscriptions && (
                    <TableCell>
                      <Button
                        variant="contained"
                        color={r.status === "Active" ? "success" : "error"}
                      >
                        {r.status}
                      </Button>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        count={totalRows}
        rowsPerPage={pageSize}
        page={page}
        onPageChange={handlePageChane}
        onRowsPerPageChange={handlePageSizeChange}
      />
    </>
  );
};

export default DataTable;
