import React from "react";
import { Box, Divider, Hidden } from "@mui/material";

import SiteCardTwo from "../SiteCardTwo";
import SiteCardMobile from "../SiteCardMobile";

const SiteTable = ({
  isTemplatesSite,
  isAdminTemplates,
  data,
  onManage,
  onEdit,
  onDelete,
}) => {
  return (
    <Box>
      <Hidden smDown>
        {data.map((site) => (
          <Box key={site.id}>
            <SiteCardTwo
              isAdminTemplates={isAdminTemplates}
              isTemplatesSite={isTemplatesSite}
              site={site}
              onEdit={onEdit}
              onDelete={onDelete}
              onManage={onManage}
            />
            <Divider flexItem />
          </Box>
        ))}
      </Hidden>
      <Hidden smUp>
        {data.map((site) => (
          <Box key={site.id}>
            <SiteCardMobile
              isTemplatesSite={isTemplatesSite}
              isAdminTemplates={isAdminTemplates}
              site={site}
              onEdit={onEdit}
              onDelete={onDelete}
              onManage={onManage}
            />
            <Divider flexItem />
          </Box>
        ))}
      </Hidden>
    </Box>
  );
};

export default SiteTable;
