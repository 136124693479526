import React from "react";
import { AuthContext } from "../../FirebaseAuth";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { useHistory } from "react-router-dom";
import "./UserProfileView.css";

const UserProfileView = () => {
  const history = useHistory();

  return (
    <AuthContext.Consumer>
      {(context) => (
        <Grid container>
          <Grid item xs={12} md={3} style={{ marginTop: 35 }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Box
                  style={{ fontStyle: "Roboto", fontWeight: 700, fontSize: 16 }}
                >
                  AVATAR
                </Box>
              </Grid>
              <Grid item>
                <Box
                  p={2}
                  style={{
                    fontStyle: "Roboto",
                    fontWeight: 400,
                    fontSize: 14,
                    color: "#5C5C5C",
                  }}
                >
                  <Avatar
                    alt={context.authUser.user.displayName}
                    src={context.authUser.user.photoURL}
                    style={{ height: "130px", width: "130px" }}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Typography color="textSecondary">
                  Update via social login
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={9}>
            <TableContainer>
              <Table className="mobile-responsive" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        NAME
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {context.authUser.user.displayName}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("/user/profile/update-name");
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        EMAIL
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {context.authUser.user.email}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("/user/profile/update-email");
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        EMAIL VERIFIED
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {context.authUser.user.emailVerified
                          ? " Verified"
                          : "Unverified email"}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{ marginLeft: "auto", marginRight: "0px" }}
                        onClick={() => {
                          if (!context.authUser.user.emailVerified)
                            history.push("/user/profile/verify-email");
                        }}
                      >
                        {context.authUser.user.emailVerified ? (
                          <VerifiedUserIcon style={{ cursor: "pointer" }} />
                        ) : (
                          <SendIcon style={{ cursor: "pointer" }} />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        PASSWORD
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        ••••••••
                      </Box>
                    </TableCell>
                    <TableCell>
                      <EditIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          history.push("/user/profile/update-password");
                        }}
                      />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Button
                        type="cancel"
                        variant="contained"
                        style={{ backgroundColor: "#F3F6F8", color: "#BD1B00" }}
                        onClick={() => {
                          history.push("/user/profile/delete");
                        }}
                      >
                        <Box
                          style={{
                            fontStyle: "Roboto",
                            fontWeight: 400,
                            fontSize: 14,
                          }}
                        >
                          {" "}
                          DELETE ACCOUNT{" "}
                        </Box>
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </AuthContext.Consumer>
  );
};

export default UserProfileView;
