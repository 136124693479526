import Joi from 'joi-browser';

const siteCreateSchema = {
    siteName: Joi.string().required().label('Site Name'),
    slug: Joi.string().required().label('Site Slug'),
    description: Joi.string().required().label('Site Description'),
    siteType: Joi.string().required().label('Site Template'),
};

const templateCreateSchema = {
    name: Joi.string().required().label('Template Name'),
    description: Joi.string().required().label('Template Description'),
    templateId: Joi.string().required().label('Template ID'),
    accountType: Joi.array().required().label('Account Type'),
    siteType: Joi.string().required().label('Site Type'),
};


export {
    siteCreateSchema,
    templateCreateSchema
}