import React from "react";
import { Paper, Box } from "@mui/material";

import DisplaySites from "../DisplaySites";
import Loader from "../Loader";

const SiteList = ({
  isTemplatesSite,
  isAdminTemplates,
  sitesList,
  onManage,
  onEdit,
  onDelete,
}) => {
  const { isLoading, data } = sitesList;
  return (
    <>
      {isLoading ? (
        <Paper>
          <Box p={2}>
            <Loader text="Loading..." />
          </Box>
        </Paper>
      ) : (
        <Paper>
          <Box>
            {!!data.length && !isLoading ? (
              <DisplaySites
                isAdminTemplates={isAdminTemplates}
                isTemplatesSite={isTemplatesSite}
                data={data}
                onEdit={onEdit}
                onDelete={onDelete}
                onManage={onManage}
              />
            ) : (
              <p style={{ textAlign: "center" }}>No Sites Yet, Deploy a new site on top right, or add external links on the bottom right.</p>
            )}
          </Box>
        </Paper>
      )}
    </>
  );
};

export default SiteList;
