import React, { useEffect, useMemo, useState } from "react";
import { Box, Card, Button, Typography, CircularProgress } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useParams } from "react-router-dom";
import { Firestore, CloudFunctions } from "../FirebaseAuth/firebase";
import { useCallback } from "react";
import { makeStyles } from "@mui/styles";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { confirmAlert } from "react-confirm-alert";
import { SitePerformanceChart } from "../SiteDetailsCard/SitePerformanceChart";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "800px",
    padding: "1rem",
    overflow: "visible",
    minHeight: "320px",
    marginTop: "2rem",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "12px",
  },
  deepScanContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: "12px",
  },
  imageButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    gap: "2px",
  },
  statusContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: "12px",
  },
  statusLabel: {
    fontSize: "12px",
    color: "#6A6A6A",
  },
  treatContainer: {
    display: "flex",
    gap: "16px",
    marginTop: 4,
  },
  treatLabel: {
    fontSize: "14px",
    color: "#6A6A6A",
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  resultLabel: {
    fontSize: "14px",
    color: "#6A6A6A",
    display: "flex",
    alignItems: "center",
    fontWeight: 500,
  },
}));

const ExternalSiteCard = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [siteData, setSiteData] = useState({ data: {}, isLoading: true });
  const [checkResult, setCheckResult] = useState({});
  const params = useParams();

  const handleRecheck = useCallback(async () => {
    setLoading(true);
    const updateWebCheckResult = CloudFunctions.httpsCallable(
      "kwallCloud-updateWebCheckResult"
    );
    const newResult = await updateWebCheckResult({
      accountId: params.accountId,
      siteId: params.siteId,
      env: "external",
    });
    if (newResult?.data) {
      setCheckResult(newResult?.data);
    }
    setLoading(false);
  }, [params.accountId, params.siteId]);

  const fetchSiteData = useCallback(async () => {
    if (!!params.siteId) {
      const siteRef = await Firestore.collection(
        `/accounts/${params.accountId}/sites`
      )
        .doc(params.siteId)
        .get();
      const siteData = siteRef.data();

      setSiteData({
        data: siteData,
        isLoading: false,
      });
      setCheckResult(siteData[`web_check_result_external`]);
    }
  }, [params.accountId, params.siteId]);

  useEffect(() => {
    fetchSiteData();
  }, [fetchSiteData]);

  const disableScan = useMemo(() => {
    return (
      siteData.data?.["deep_scan_time_external"] &&
      (siteData.data?.["deep_scan_time_external"]?.seconds || 0 + 86400) *
        1000 <
        Date.now()
    );
  }, [siteData.data]);
  console.log("***************siteData: ", siteData.data.url);
  const handleRequestScan = useCallback(async () => {
    const requestDeepScan = CloudFunctions.httpsCallable(
      "kwallCloud-requestDeepScan"
    );

    await requestDeepScan({
      siteId: params.siteId,
      domain: siteData.data.url,
      env: "external",
      site_name_slug: siteData.data.slug,
      accountId: params.accountId,
    });
  }, [params.accountId, params.siteId, siteData.data]);

  const handleClickDeepScan = useCallback(async () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "24px",
              maxWidth: "360px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography fontSize={24} fontWeight={700}>
                Alert
              </Typography>
              <CancelIcon
                onClick={onClose}
                sx={{ cursor: "pointer", color: "#666", marginTop: "-12px" }}
              />
            </Box>
            <Typography fontSize={20} color="#666">
              This scan will take several hours to complete
            </Typography>
            <Box sx={{ display: "flex", gap: "8px" }}>
              <Button
                onClick={() => {
                  handleRequestScan();
                  onClose();
                }}
                variant="contained"
              >
                Run Scan
              </Button>
              <Button onClick={onClose} variant="outlined" color="error">
                Cancel
              </Button>
            </Box>
          </Card>
        );
      },
    });
  }, [handleRequestScan]);

  const handleClickViewScan = useCallback(() => {
    const path = `https://reporting.accorbis.com${
      siteData.data?.[`deep_scan_result_external`]
    }/index.html`;
    window.open(path, "_blank");
  }, [siteData.data]);

  return (
    <Card className={classes.cardContainer}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography className={classes.resultLabel}>
          WEB CHECK RESULT
        </Typography>
        <Box className={classes.statusContainer}>
          {loading ? (
            <CircularProgress
              variant="indeterminate"
              sx={{ color: "#9F9F9F" }}
              size={14}
            />
          ) : (
            checkResult?.lastUpdated && (
              <Typography className={classes.statusLabel}>
                {`Last checked at ${new Date(
                  checkResult?.lastUpdated
                ).toLocaleString()}`}
              </Typography>
            )
          )}
        </Box>
      </Box>
      {checkResult?.threats && (
        <Box className={classes.treatContainer}>
          {checkResult.threats.phishTank?.url0?.in_database === "false" ? (
            <Typography className={classes.treatLabel}>
              <CheckCircleIcon color="success" sx={{ fontSize: "16px" }} /> No
              Phishing Found
            </Typography>
          ) : (
            <Typography className={classes.treatLabel}>
              <CancelIcon color="error" sx={{ fontSize: "16px" }} /> Phishing
              Identified
            </Typography>
          )}
          {checkResult.threats.urlHaus.query_status === "no_results" ? (
            <Typography className={classes.treatLabel}>
              <CheckCircleIcon color="success" sx={{ fontSize: "16px" }} /> No
              Malware Found
            </Typography>
          ) : (
            <Typography className={classes.treatLabel}>
              <CancelIcon color="error" sx={{ fontSize: "16px" }} /> Malware
              Found
            </Typography>
          )}
        </Box>
      )}
      <SitePerformanceChart webCheckResult={checkResult} />

      <Box className={classes.buttonContainer}>
        <Button
          size="small"
          onClick={handleRecheck}
          disabled={loading}
          loading={loading}
          fullWidth
          variant="contained"
        >
          {checkResult ? "RECHECK" : "WEB CHECK"}
        </Button>
        <Box className={classes.deepScanContainer}>
          <Button
            className={classes.imageButton}
            onClick={handleClickViewScan}
            disabled={!siteData.data?.[`deep_scan_result_external`]}
            variant="contained"
            fullWidth
          >
            <Typography sx={{ fontSize: 14 }}>View Last Scan</Typography>
            <InsertDriveFileIcon fontSize="12px" />
          </Button>
          <Button
            className={classes.imageButton}
            onClick={handleClickDeepScan}
            disabled={disableScan}
            variant="contained"
            fullWidth
          >
            <Typography sx={{ fontSize: 14 }}>Deep Scan</Typography>
            <AutorenewIcon fontSize="12px" />
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default ExternalSiteCard;
