import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Stack,
  Button,
  Grid,
  Box,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import SiteLists from "../../../../components/SiteList";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";
import { siteTypesArr } from "../../../../utils/constants";

const useStyles = makeStyles((theme) => ({
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  btnAdd: {
    fontSize: 16,
    color: "#0a79a6",
    textDecoration: "underline",
    cursor: "pointer",
  },
  importBtnContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

const SiteList = () => {
  const title = "Sites";

  const history = useHistory();
  const classes = useStyles();
  const initialSiteState = {
    data: [],
    isLoading: true,
  };

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [sitesList, setSitesList] = useState(initialSiteState);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const [selectedSiteType, setSelectedSiteType] = useState("");
  const [filteredSites, setFilteredSites] = useState([]);
  const [search, setSearch] = useState("");

  const handleManage = useCallback(
    (id) => {
      const url = `/account/${currentAccount.id}/site-details/${id}`;
      history.push(url);
    },
    [currentAccount.id, history]
  );

  useEffect(() => {
    let tempList = sitesList.data;
    if (sitesList.length === 0) return;
    if (selectedSiteType && selectedSiteType !== "All") {
      tempList = tempList.filter((site) => site.siteType === selectedSiteType);
    }
    if (search.trim()) {
      tempList = tempList.filter((site) =>
        site.siteName.toLowerCase().includes(search.trim().toLowerCase())
      );
    }

    setFilteredSites(
      tempList?.sort((a, b) => (a.siteName < b.siteName ? -1 : 1))
    );
  }, [search, selectedSiteType, sitesList.data, sitesList.length]);

  const fetchSitesList = useCallback(() => {
    try {
      const sitesCollectionsRef = Firestore.collection(
        `/accounts/${currentAccount.id}/sites`
      ).where("isMyTemplate", "==", false);
      // .orderBy('createdAt', 'desc');
      sitesCollectionsRef.onSnapshot((querySnapshot) => {
        const sitesArr = [];
        if (!!querySnapshot) {
          querySnapshot.forEach((doc) => {
            if (!doc.data().status || doc.data().status !== "deleted") {
              sitesArr.push({ ...doc.data(), id: doc.id });
            }
          });
        }
        setSitesList({
          data: sitesArr,
          isLoading: false,
        });
      });
    } catch (ex) {
      console.log("___ ex", ex);
    }
  }, [currentAccount.id]);

  const handleCreate = useCallback(
    (isExternal) => {
      history.push({
        pathname: `/account/${currentAccount.id}/sites/create`,
        state: { isExternal },
      });
    },
    [history, currentAccount.id]
  );

  const handleImportSites = useCallback(() => {
    history.push(`/account/${currentAccount.id}/sites/import`);
  }, [history, currentAccount.id]);

  useEffect(() => {
    fetchSitesList();
  }, [fetchSitesList]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: false,
      },
    ]);
  }, [setBreadcrumb, title, currentAccount.id, currentAccount.name]);

  return (
    <Stack spacing={3}>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        <Grid
          container
          alignItems="center"
          direction="row"
          justifyContent="sapce-between"
        >
          <Grid container item spacing={2} md={6} alignItems="center">
            <Grid item xs={12} md={6}>
              <TextField
                label="Search"
                name="search"
                fullWidth
                style={{ backgroundColor: "#ffffff" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel id="siteType">Site Type</InputLabel>
                <Select
                  labelId="siteType"
                  name="siteType"
                  fullWidth
                  value={selectedSiteType}
                  onChange={(e) => setSelectedSiteType(e.target.value)}
                  input={<OutlinedInput label="Site Type" />}
                  style={{ backgroundColor: "#ffffff" }}
                >
                  {siteTypesArr.map((siteType) => (
                    <MenuItem key={siteType.value} value={siteType}>
                      {siteType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
            alignItems="center"
            display="flex"
            justifyContent="flex-end"
          >
            <Stack
              direction="row"
              alignItems="ccenter"
              sx={{ marginTop: "16px" }}
              spacing={1}
            >
              <Box
                className={classes.btnContainer}
                alignItems="center"
                justifyContent="center"
              >
                <Box
                  variant="contained"
                  className={classes.btnAdd}
                  onClick={() => handleCreate(true)}
                >
                  Add Site Link / Platform
                </Box>
              </Box>
              <Button variant="contained" onClick={() => handleCreate(false)}>
                CREATE NEW SITE
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <SiteLists
        sitesList={{ data: filteredSites, isLoading: sitesList.isLoading }}
        onManage={handleManage}
      />
      <Box className={classes.importBtnContainer}>
        <Button variant="contained" onClick={handleImportSites}>
          Import Sites
        </Button>
      </Box>
    </Stack>
  );
};

export default SiteList;
