import * as React from 'react';
import { styled } from '@mui/system';

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #ddd;
  }
`;

const InvoiceDetailsTable = ({ inVoiceData }) => {
    console.log('___ invoiceData', inVoiceData);


    return (
        <Root sx={{ maxWidth: '100%', width: 500 }}>
            <table aria-label="custom pagination table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Details</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width: 160 }}>Account ID</td>
                        <td align="right">
                            {inVoiceData.accountId}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 160 }}>Site ID</td>
                        <td align="right">
                            {inVoiceData.siteId}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 160 }}>Currenct</td>
                        <td align="right">
                            {inVoiceData.currency}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 160 }}>Status</td>
                        <td align="right">
                            {inVoiceData.status}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 160 }}>Amount Due</td>
                        <td align="right">
                            {inVoiceData.amountDue}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 160 }}>Amount Paid</td>
                        <td align="right">
                            {inVoiceData.amountPaid}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 160 }}>Tax</td>
                        <td align="right">
                            {inVoiceData.tax ? inVoiceData.tax : '0'}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 160 }}>Sub Total</td>
                        <td align="right">
                            {inVoiceData.subTotal}
                        </td>
                    </tr>
                    <tr>
                        <td style={{ width: 160 }}>Total</td>
                        <td align="right">
                            {inVoiceData.total}
                        </td>
                    </tr>
                </tbody>
            </table>
        </Root>
    );
}

export default InvoiceDetailsTable;