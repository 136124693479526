import { Button, Card, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { memo } from "react";

const useStyles = makeStyles((theme) => ({
  helpContainer: {
    display: "flex",
    alignItems: "center",
    gap: "24px",
    padding: "16px",
    marginTop: "36px",
  },
}));

export const HelpCenter = memo(() => {
  const classes = useStyles();

  return (
    <Card className={classes.helpContainer}>
      <Typography>Let Us Help You Take Your Site Live</Typography>
      <Button
        variant="outlined"
        onClick={() => window.open("https://help.kwallcompany.com/", "_blank")}
      >
        Help Center
      </Button>
      <Button
        variant="contained"
        onClick={() =>
          window.open(
            "https://help.kwallcompany.com/hc/en-us/requests/new",
            "_blank"
          )
        }
      >
        Request Go Live Help
      </Button>
    </Card>
  );
});
