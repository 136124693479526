import React, { useCallback } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box,
  Link,
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    paddingLeft: "18px !important",
    paddingTop: "18px !important",
  },
  card: {
    height: "100%",
    padding: "15px",
    flexDirection: "column",
    display: "flex",
  },
  media: {
    maxWidth: "160px",
  },
  button: {
    display: "flex",
    marginTop: "auto",
    gap: "1rem",
  },
  content: {
    padding: "7px",
  },
  priceLabel: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#407543",
  },
  lowestContainer: {
    display: "flex",
    flexDirection: "column",
  },
  lowestLabel: {
    fontSize: "12px",
  },
  estimationLabel: {
    backgroundColor: "#dcdbdc",
    fontSize: "14px",
    borderRadius: "4px",
    width: "fit-content",
    padding: "0 8px",
  },
}));

const ServiceCardGrid = ({ service }) => {
  const history = useHistory();
  const params = useParams();
  const classes = useStyles();

  const handleClickRequest = useCallback(() => {
    if (params.accountId) {
      history.push(`/account/${params.accountId}/service/${service.id}`, {
        siteId: params.siteId,
      });
    } else {
      history.push(`/marketplace/request/${service.id}`);
    }
  }, [service, history, params]);

  return (
    <Grid item xs={12} sm={6} md={4} className={classes.cardContainer}>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          component="img"
          image={service.imageUrl}
        />
        <CardContent className={classes.content}>
          <Typography className={classes.estimationLabel}>
            {service.estimatedTime}
          </Typography>
          <Typography gutterBottom variant="h6" component="div">
            {service?.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {`Service By ${service?.provider}`}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {service?.description}
          </Typography>
          {service.link && (
            <Link href={service.link} target="_blank" variant="body2">
              Learn More
            </Link>
          )}
        </CardContent>
        <CardActions className={classes.button}>
          <Button variant="contained" onClick={handleClickRequest}>
            Request
          </Button>
          {service.price ? (
            <Typography className={classes.priceLabel}>
              {service.price}
            </Typography>
          ) : (
            service.lowestPrice && (
              <Box className={classes.lowestContainer}>
                <Typography className={classes.lowestLabel}>
                  Starting at
                </Typography>
                <Typography className={classes.priceLabel}>
                  ${service.lowestPrice}
                </Typography>
              </Box>
            )
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default ServiceCardGrid;
