import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { FirebaseAuth, Firestore } from "../../FirebaseAuth/firebase";
import Loader from "../../Loader";
import { confirmAlert } from "react-confirm-alert";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
  setLocalStorage,
} from "../../../utils/localStorage";
import { isKWALLAdmin } from "../../../utils";

const UserOrganizationView = ({ organizationId }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState([]);
  const currentOrganizationId =
    getLocalStorage(LOCAL_STORAGE_CURRENT_ORG)?.id || organizationId;
  const currentUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);

  const getAccounts = useCallback(async () => {
    setLoading(true);
    let records = [];
    let organizationSnapshots;
    if (currentOrganizationId) {
      organizationSnapshots = await FirebaseAuth.firestore()
        .collection("organizations")
        .where("__name__", "==", currentOrganizationId)
        .get();
    } else {
      organizationSnapshots = await FirebaseAuth.firestore()
        .collection("organizations")
        .where("access", "array-contains", FirebaseAuth.auth().currentUser.uid)
        .get();
    }
    organizationSnapshots.forEach((org) => {
      records.push({
        id: org.id,
        name: org.data().name,
        accountType: org.data().accountType,
        description: org.data().description || "",
        primaryColor: org.data().primaryColor || "",
        secondaryColor: org.data().secondaryColor || "",
        imageUrl: org.data().imageUrl || "",
        primaryFont: org.data().primaryFont || "Manuale",
        secondaryFont: org.data().secondaryFont || "Manuale",
        tertiaryFont: org.data().tertiaryFont || "Manuale",
      });
    });
    setOrganization(records[0]);
    setLoading(false);
  }, [currentOrganizationId]);

  const handleDeleted = useCallback(async () => {
    try {
      const organizationRef = Firestore.collection("organizations").doc(
        currentOrganizationId || organization.id
      );
      await organizationRef.set({ status: "deleted" }, { merge: true });
      const userOrganizationRef = Firestore.collection("organizations").where(
        "access",
        "array-contains",
        FirebaseAuth.auth().currentUser.uid
      );
      const userOrganizationSnapshot = await userOrganizationRef.get();
      let organizations = [];
      userOrganizationSnapshot.forEach((org) => {
        if (!org.data().status || org.data().status !== "deleted")
          organizations.push({
            ...org.data(),
            id: org.id,
          });
      });
      if (organizations.length > 0) {
        setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, organizations?.[0]);
      }
      const path = isKWALLAdmin(currentUser?.role)
        ? "/organization_list"
        : "/organization/switch-organization";
      history.push(path);
    } catch (ex) {}
  }, [currentOrganizationId, organization.id, currentUser?.role, history]);

  const handleDelete = useCallback(() => {
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure you want to delete this? This cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleted(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  }, [handleDeleted]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  return (
    <>
      {loading ? (
        <Loader text="Loading Organization..."></Loader>
      ) : (
        <Grid container>
          <Grid item xs={3} style={{ marginTop: 35 }}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                <Box
                  style={{ fontStyle: "Roboto", fontWeight: 700, fontSize: 16 }}
                >
                  LOGO
                </Box>
              </Grid>
              <Grid item>
                <Box
                  p={2}
                  style={{
                    fontStyle: "Roboto",
                    fontWeight: 400,
                    fontSize: 14,
                    color: "#5C5C5C",
                  }}
                >
                  <Avatar
                    alt={organization?.imageUrl}
                    src={organization?.imageUrl}
                    style={{ height: "130px", width: "130px" }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        NAME
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {organization?.name}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        TYPE
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {organization?.accountType}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        DESCRIPTION
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {organization?.description}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        PRIMARY COLOR
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {organization?.primaryColor}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        SECONDARY COLOR
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {organization?.secondaryColor}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        PRIMARY FONT
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {organization?.primaryFont}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        SECONDARY FONT
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {organization?.secondaryFont}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 700,
                          fontSize: 16,
                        }}
                      >
                        TERTIARY FONT
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        style={{
                          fontStyle: "Roboto",
                          fontWeight: 400,
                          fontSize: 16,
                          color: "#5C5C5C",
                        }}
                      >
                        {organization?.tertiaryFont}
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Button
                        type="cancel"
                        variant="contained"
                        style={{ backgroundColor: "#F3F6F8", color: "#BD1B00" }}
                        onClick={handleDelete}
                      >
                        DELETE ORGANIZATION
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Button
                        type="cancel"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          history.push(
                            currentOrganizationId
                              ? `/organization_list/edit/${currentOrganizationId}`
                              : `/user/organization/${organization?.id}`
                          );
                        }}
                      >
                        EDIT ORGANIZATION
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default UserOrganizationView;
