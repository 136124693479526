export const termsUrl =
  "https://docs.google.com/document/d/13TDfx8W7M79Ub6veRsYD-PusZNEXH4jNP5Ff3LUptWY/edit?usp=sharing";

export const autoEnrollInformation =
  "https://help.kwallcompany.com/hc/en-us/articles/19850180569107";

export const webCheckUrl = "https://web-check.accorbis.com/results/";

export const accountTypesArr = [
  "Higher Education",
  "K-12",
  "City/State Government - Public Sector",
  "Enterprise Business",
  "Agency",
];
export const fontArray = [
  "Manuale",
  "Playfair Display",
  "PT Serif",
  "Roboto",
  "Roboto Condensed",
  "Montserrat",
  "Open Sans",
  "Jost",
];

export const siteTypesArr = ["All", "Drupal", "Wordpress"];
export const templateTypesArr = ["All", "Default Template", "My Template"];

export const planFormErrors = {
  name: {
    isError: false,
    message: "Plan Name must be between 1 to 100 characters.",
  },
  description: {
    isError: false,
    message: "Plan Description must be between 1 to 200 characters.",
  },
  price: {
    isError: false,
    message: "Price is required.",
  },
  position: {
    isError: false,
    message: "Position is required.",
  },
  currency: {
    isError: false,
    message: "Currency is required.",
  },
  kwallPaymentId: {
    isError: false,
    message: "kwallPaymentId is required.",
  },
  stripePriceId: {
    isError: false,
    message: "stripePriceId is required.",
  },
  paymentCycle: {
    isError: false,
    message: "paymentCycle is required.",
  },
};

export const serviceFormErrors = {
  name: {
    isError: false,
    message: "Service Name must be between 1 to 100 characters.",
  },
  description: {
    isError: false,
    message: "Service Description must be between 1 to 200 characters.",
  },
  type: {
    isError: false,
    message: "Service Type is required.",
  },
  provider: {
    isError: false,
    message: "Service Provider is required.",
  },
};

export const organizationFormErrors = {
  name: {
    isError: false,
    message: "Organization Name must be between 1 to 100 characters.",
  },
  description: {
    isError: false,
    message: "Organization Description must be between 1 to 200 characters.",
  },
  organizationCode: {
    isError: false,
    message: "Organization Code must be between 3 to 4 characters.",
  },
  accountType: {
    isError: false,
    message: "Organization Type is required.",
  },
};

export const siteFormErrors = {
  siteName: {
    isError: false,
    message: "Site Name is required.",
  },
  slug: {
    isError: false,
    message: "Site Slug is required.",
  },
  description: {
    isError: false,
    message: "Site Description is required.",
  },
  siteType: {
    isError: false,
    message: "Template is required.",
  },
  url: {
    isError: false,
    message: "Site URL is required.",
  },
};
// Not sure which currency will be used...
export const planCurrencies = [
  {
    label: "$",
    value: "usd",
  },
  {
    label: "£",
    value: "gbp",
  },
  {
    label: "€",
    value: "euro",
  },
];
// Not sure which cycles will be used...
export const planPaymentCycles = [
  {
    label: "Month",
    value: "mo",
  },
  {
    label: "Year",
    value: "yr",
  },
];

export const roles = [
  {
    label: "Admin",
    value: "admin",
  },
  {
    label: "Org Admin",
    value: "orgAdmin",
  },
  {
    label: "KWALL Admin",
    value: "kwallAdmin",
  },
];

export const serviceTypes = [
  {
    label: "Creative Services",
    value: "creativeServices",
  },
  {
    label: "Development Services",
    value: "developmentServices",
  },
  {
    label: "Project Management",
    value: "projectManagement",
  },
];

export const serviceFilterTypes = [
  {
    label: "All Services",
    value: "allServices",
  },
  ...serviceTypes,
];
