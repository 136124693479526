import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Container, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BreadcrumbContext } from "../../../components/Breadcrumb";
import {
  FirebaseAuth,
  Firestore,
} from "../../../components/FirebaseAuth/firebase";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { ServiceEditCard } from "../../../components/Service/ServiceEditCard";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../../utils/localStorage";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  btnContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    padding: theme.spacing(1.5),
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
}));

const ServiceLists = () => {
  const history = useHistory();
  const classes = useStyles();
  const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [services, setServices] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = useCallback(() => {
    history.push("/serviceLists/create");
  }, [history]);

  const getServiceLists = useCallback(() => {
    const serviceQuery = FirebaseAuth.firestore()
      .collection("services")
      .orderBy("type", "asc");
    let temp = [];
    setIsLoading(true);
    serviceQuery.get().then((serviceSnapShots) => {
      serviceSnapShots.forEach((doc) => {
        if (!currentAccount?.id || currentOrganization?.id) {
          temp.push({ id: doc.id, ...doc.data() });
        } else if (!doc.data().showAccounts && !doc.data().showOrganizations) {
          temp.push({ id: doc.id, ...doc.data() });
        } else if (
          ((doc.data().showAccounts.length === 0 &&
            doc.data().showOrganizations.length === 0) ||
            doc.data().showAccounts.includes(currentAccount.id) ||
            doc.data().showOrganizations.includes(currentOrganization.id)) &&
          !doc.data().excludeCustomerID.includes(currentAccount.id) &&
          !doc.data().excludeOrganizationID.includes(currentOrganization.id)
        ) {
          temp.push({ ...doc.data(), id: doc.id });
        }
      });
      setServices(temp);
      setIsLoading(false);
    });
  }, [currentAccount?.id, currentOrganization?.id]);

  const handleDeleteService = useCallback(
    async (id) => {
      try {
        await Firestore.collection("services").doc(id).delete();
        getServiceLists();
        toast.success("Deleted Successfully");
      } catch (ex) {
        toast.error("Something went wrong");
      }
    },
    [getServiceLists]
  );

  const handleDelete = useCallback(
    (id) => {
      confirmAlert({
        title: "Are you sure?",
        message: "Are you sure you want to delete this? This cannot be undone.",
        buttons: [
          {
            label: "Yes",
            onClick: async () => handleDeleteService(id),
          },
          {
            label: "No",
          },
        ],
      });
    },
    [handleDeleteService]
  );

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: "Services",
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  useEffect(() => {
    getServiceLists();
  }, [getServiceLists]);

  return (
    <Container className={classes.container}>
      <Box className={classes.btnContainer}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handleAdd}
        >
          <Box>Create New Service</Box>
        </Button>
      </Box>
      {!isLoading &&
        services.length > 0 &&
        services.map((service) => {
          return (
            <ServiceEditCard
              service={service}
              key={service.id}
              handleDelete={() => handleDelete(service.id)}
            />
          );
        })}
    </Container>
  );
};

export default ServiceLists;
