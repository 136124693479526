import React, { useCallback, useState } from 'react';
import { Box, Typography, TextField, Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import './index.css';

const useStyles = makeStyles(() => ({
    container: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: 24,
        position: "relative",
    },
    listHeader: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "0 12px 12px",
    },
    featureItem: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center"
    },
    featureInput: {
        flex: 1,
        marginRight: 24
    },
    styledIcon: {
        cursor: "pointer",
        marginLeft: 12
    }
}))

const FeatureForm = ({ features = [], setFeatures }) => {
    const classes = useStyles();

    const [selected, setSelected] = useState();
    const [selectedFeature, setSelectedFeature] = useState();
    const [newFeature, setNewFeature] = useState("");
    const [addNew, setAddNew] = useState(false);

    const handleSave = useCallback((id) => {
        const tmp = features.map((feature, index) => {
            if (index === id) {
                return selectedFeature;
            } else {
                return feature
            }
        })
        setFeatures(tmp);
        setNewFeature("");
        setAddNew(false);
        setSelected(false);
        setSelectedFeature("");
    }, [selectedFeature, features, setFeatures]);

    const handleEdit = useCallback((id) => {
        setSelected(id);
        setSelectedFeature(features[id])
    }, [features]);

    const handleDelete = useCallback((id) => {
        const tmp = features.filter((item, index) => id !== index)
        setFeatures(tmp);
    }, [features, setFeatures]);

    const handleAdd = useCallback(() => {
        if (!newFeature) return;
        setFeatures([...features, newFeature]);
        setNewFeature("");
        setAddNew(false);
    }, [features, newFeature, setFeatures]);

    return (
        <Paper className={classes.container}>
            <Box className={classes.listHeader}>
                <Typography variant='h6'>Features</Typography>
                <AddCircleIcon onClick={() => setAddNew(true)} className={classes.styledIcon} color="primary" />
            </Box>
            {features?.map((feature, key) => {
                return (
                    <Box key={key} className={classes.featureItem}>
                        <TextField
                            disabled={selected !== key}
                            className={classes.featureInput}
                            value={selected === key ? selectedFeature : feature}
                            onChange={(e) => setSelectedFeature(e.target.value)}
                        />
                        {selected === key ? <SaveIcon
                            onClick={() => handleSave(key)}
                            className={classes.styledIcon}
                            color="primary"
                        /> : <EditIcon
                            onClick={() => handleEdit(key)}
                            className={classes.styledIcon}
                            color="primary"
                        />}
                        <DeleteIcon onClick={() => handleDelete(key)} className={classes.styledIcon} color="primary" />
                    </Box>
                )
            })}
            {addNew && <Box className={classes.featureItem}>
                <TextField
                    className={classes.featureInput}
                    value={newFeature}
                    onChange={(e) => setNewFeature(e.target.value)}
                />
                <SaveIcon onClick={handleAdd} className={classes.styledIcon} color="primary" />
                <DeleteIcon onClick={() => setAddNew(false)} className={classes.styledIcon} color="primary" />
            </Box>}
        </Paper>
    );
}

export default FeatureForm;