
const ListInvoicesApi = (sites, page, pageSize) => {
    return new Promise((resolve, reject) => {
        const start = page * pageSize;

        if (sites.length === 0) {
            resolve({
                total: sites.length,
                data: []
            })
            return
        }

        if (sites.length === 1) {
            resolve({
                total: sites.length,
                data: sites
            })
            return
        }

        if (start >= 0 && start < sites.length - 1) {
            resolve({
                total: sites.length,
                data: sites
            })
        } else {
            reject('out of range');
        }
    });
}

export {
    ListInvoicesApi,
}