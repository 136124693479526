import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Container,
  Paper,
  Box,
  Alert,
  Grid,
  Avatar,
  Stack,
  IconButton,
  Typography,
  Input,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { makeStyles } from "@mui/styles";
import { AuthContext } from "../../../components/FirebaseAuth";

import {
  FirebaseStorage,
  Firestore,
} from "../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from "../../../components/Breadcrumb";
import { organizationFormErrors } from "../../../utils/constants";
import "./index.css";
import { OrganizationForm } from "../Organization/OrganizationForm/OrganizationForm";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
  setLocalStorage,
} from "../../../utils/localStorage";

const useStyles = makeStyles(() => ({
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 130,
    height: 130,
    marginBottom: 24,
  },
  uploadInput: {
    display: "none",
  },
}));

const initialOrgData = {
  name: "",
  description: "",
  accountType: "",
  acceptTerms: false,
  access: [],
  accounts: [],
  admins: [],
  organizationCode: "",
  machineToken: "",
  imageUrl: "",
  pantheonOrgID: "",
};

const CreateOrganization = () => {
  const history = useHistory();
  const classes = useStyles();
  const currentUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);
  const { authUser } = useContext(AuthContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [errors, setErrors] = useState();
  const [inSubmit, setInSubmit] = useState(false);
  const [organizationData, setOrganizationData] = useState(initialOrgData);

  const handleCreate = useCallback(
    async (ev) => {
      ev.preventDefault();
      setInSubmit(true);
      if (!organizationData.name) {
        setErrors(organizationFormErrors.name.message);
        return;
      }
      if (!organizationData.description) {
        setErrors(organizationFormErrors.description.message);
        return;
      }
      if (!organizationData.accountType) {
        setErrors(organizationFormErrors.accountType.message);
        return;
      }
      try {
        const response = await Firestore.collection("organizations").add({
          ...organizationData,
          userId: authUser.user.uid,
          admins: [authUser.user.uid],
          access: [authUser.user.uid],
        });
        setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, {
          ...organizationData,
          id: response.id,
          userId: authUser.user.uid,
          admins: [authUser.user.uid],
          access: [authUser.user.uid],
        });
        setInSubmit(false);
        history.push("/organization/switch-organization");
        return;
      } catch (ex) {
        console.log("____ ex", ex);
      }
    },
    [organizationData, history, authUser.user.uid]
  );

  const handleChange = useCallback(({ target: { name, value } }) => {
    if (name === "name" && value.length > 100) return;
    if (name === "description" && value.length > 200) return;
    if (name === "organizationCode" && value.length > 4) return;
    setOrganizationData((prevData) => ({ ...prevData, [name]: value }));
  }, []);

  const handleUpload = async (image) => {
    try {
      const downloadUrl = await FirebaseStorage.ref(
        `organizations/${image.name}`
      ).put(image);
      return downloadUrl.ref.getDownloadURL();
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const handleChangeImage = useCallback(async ({ target: { files } }) => {
    if (!!files[0]) {
      const uploadUrl = await handleUpload(files[0]);
      setOrganizationData((prevOrg) => ({ ...prevOrg, imageUrl: uploadUrl }));
    }
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/organization_list",
        text: "Organizations",
        active: false,
      },
      {
        to: null,
        text: "Create Organization",
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  return (
    <Container>
      <Paper>
        <Grid container>
          <Grid item xs={12} md={3} className={classes.imageContainer}>
            <Avatar
              alt={organizationData.imageUrl}
              src={organizationData.imageUrl}
              className={classes.image}
            />
            <Stack>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  name="image"
                  type="file"
                  onChange={handleChangeImage}
                  className={classes.uploadInput}
                />
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloudUploadIcon />
                  <Box>
                    <Typography ml={1}>Update Image</Typography>
                  </Box>
                </IconButton>
              </label>
            </Stack>
          </Grid>
          <Grid item xs={12} md={9}>
            <Box p={2}>
              {errors && (
                <Alert
                  severity="error"
                  dismissible={true}
                  onDismiss={() => setErrors(null)}
                >
                  {errors}
                </Alert>
              )}
              <div className="card-body">
                <OrganizationForm
                  handleSubmit={handleCreate}
                  organizationData={organizationData}
                  inSubmit={inSubmit}
                  handleChange={handleChange}
                  userData={currentUser}
                />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default CreateOrganization;
