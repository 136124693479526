import * as React from "react";
import {
  Grid,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Link,
} from "@mui/material";
import { defaultTemplateUrl } from "../../utils/index";
import { useIsMobileView } from "../../hooks/useIsMobileView";

import "./index.css";

const ThemeCard = ({
  theme,
  isEdit,
  finalSelectedTemplate,
  onSelectTemplate,
}) => {
  const { id, name, image, description, isNoted, URL } = theme;
  const isMobileView = useIsMobileView();

  if (isEdit && !theme) {
    return (
      <Card
        style={{
          width: 170,
          height: 164,
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.16)",
          borderRadius: 4,
          background: "#FFFFFF",
          cursor: "pointer",
        }}
        className={"selected-card"}
        onClick={() => onSelectTemplate(theme)}
      >
        <CardActionArea>
          <Box
            style={{
              backgroundImage: `url(${defaultTemplateUrl})`,
              backgroundSize: "cover",
              height: 139,
              padding: 10,
              objectFit: "contain",
              backgroundRepeat: "no-repeat",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            ></Grid>
          </Box>
          <Box
            style={{
              fontWeight: 400,
              fontSize: 14,
              fontFamily: "Roboto",
              textAlign: "center",
              margin: 2,
              paddingBottom: 3,
            }}
          >
            No Template Selected
          </Box>
        </CardActionArea>
      </Card>
    );
  }

  return (
    <Card
      className={
        !!finalSelectedTemplate && id === finalSelectedTemplate.id && !isEdit
          ? "site-card selected"
          : "site-card"
      }
    >
      <CardMedia
        component="img"
        image={image ? image : defaultTemplateUrl}
        alt="template logo"
        style={{ width: 164, height: 164, padding: 10, objectFit: "cover" }}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0 12px",
          position: "relative",
        }}
      >
        <CardContent>
          <Typography
            gutterBottom
            sx={{ fontSize: isMobileView ? "14px" : "16px", fontWeight: 700 }}
            component="div"
          >
            {isNoted ? "Noted" : name}
          </Typography>
          <Typography
            sx={{ fontSize: isMobileView ? "12px" : "14px" }}
            color="text.secondary"
          >
            {description}
          </Typography>
          {URL && (
            <Link href={URL} target="_blank" rel="noopener noreferrer">
              learn more
            </Link>
          )}
        </CardContent>
        {!isEdit ? (
          <CardActions
            sx={{ position: "absolute", bottom: "12px", padding: "0 12px" }}
          >
            {!!finalSelectedTemplate && id === finalSelectedTemplate.id ? (
              <Button
                size="small"
                style={{
                  backgroundColor: "#F3F6F8",
                  color: "#BD1B00",
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "Roboto",
                }}
                onClick={() => onSelectTemplate({})}
              >
                Cancel Selection
              </Button>
            ) : (
              <Button
                size="small"
                style={{
                  backgroundColor: "#F3F6F8",
                  fontSize: 12,
                  fontWeight: 400,
                  fontFamily: "Roboto",
                }}
                onClick={() => onSelectTemplate(theme)}
              >
                Use This Template
              </Button>
            )}
          </CardActions>
        ) : null}
      </Box>
    </Card>
  );
};

export default ThemeCard;
