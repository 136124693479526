import React from 'react';
import {
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Grid,
    TextField,
    Box,
    Container
    // FormControlLabel,
    // Checkbox,

} from '@mui/material';
import DataSaverOnIcon from '@mui/icons-material/DataSaverOn';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddDomainDialog = ({ open, isSubmitting, getValues, register, errors, onClose, onSubmit, handleSubmit }) => {

    return (
        <div>
            <Dialog
                fullScreen
                open={open}
                onClose={onClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={onClose}
                            aria-label="close"
                        >
                            <CancelRoundedIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                            Add New Domain
                        </Typography>
                        <Button
                            autoFocus
                            startIcon={<DataSaverOnIcon />}
                            color="inherit"
                            onClick={handleSubmit(onSubmit)}
                        >
                            Add
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="md">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box m={4}>
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={3}>
                                <Grid item xs={12}>
                                    <TextField
                                        id="id"
                                        label="Domain Name"
                                        error={!!errors.id}
                                        helperText={errors.id && errors.id.type === "required" && "Please enter Domain Name"}
                                        {...register('id', { required: true, maxLength: 40 })}
                                        variant="outlined"
                                        fullWidth />
                                </Grid>
                                {/* <Grid item xs={12}>
                                <TextField
                                    id="type"
                                    label="Record Type"
                                    error={!!errors.type}
                                    helperText={errors.type && errors.type.type === "required" && "Please enter Record Type"}
                                    {...register('type', { required: true, maxLength: 40 })}
                                    variant="outlined"
                                    fullWidth />
                            </Grid> */}
                                {/* <Grid item xs={12}>
                                <TextField
                                    id="statusMessage"
                                    label="Status Message"
                                    error={!!errors.statusMessage}
                                    helperText={errors.statusMessage && errors.statusMessage.type === "required" && "Please enter Status Message"}
                                    {...register('statusMessage', { required: true, maxLength: 40 })}
                                    variant="outlined"
                                    fullWidth />
                            </Grid> */}
                                {/* <Grid item xs={12}>
                                <TextField
                                    id="status"
                                    label="Status"
                                    error={!!errors.status}
                                    helperText={errors.status && errors.status.type === "required" && "Please enter Status"}
                                    {...register('status', { required: true, maxLength: 40 })}
                                    variant="outlined"
                                    fullWidth />
                            </Grid> */}
                                {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox {...register("primary")} id='primary' defaultChecked={getValues('primary')} />} label="Recommended Value" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox {...register("deletable")} id='deletable' defaultChecked={getValues('deletable')} />} label="Detected Value" />
                            </Grid> */}
                                <Grid item xs={12}>
                                    <Button
                                        type='submit'
                                        variant='contained'
                                        fullWidth
                                        disabled={isSubmitting}
                                        style={{ borderRadius: 20, marginTop: 20 }}>
                                        Add
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </form>
                </Container>
            </Dialog>
        </div>
    );
}

export default AddDomainDialog;