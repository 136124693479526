import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Grid,
  Divider,
  Box,
  Button,
  Typography,
  LinearProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";

import { defaultTemplateUrl } from "../../utils/index";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../utils/localStorage";
import { PerformanceDetailsModal } from "../SiteDetailsCard/PerformanceDetailsModal";

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: "#6A6A6A",
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: 14,
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    color: "#0A79A6",
    textDecorationColor: "#0A79A6",
    fontSize: 18,
    fontWeight: 400,
  },
  badge: {
    fontSize: 10,
    backgroundColor: "#f3f6f8",
    borderRadius: 4,
    padding: "4px 8px",
    color: "#6A6A6A",
    width: "fit-content",
    marginBottom: 8,
  },
  linkContainer: {
    display: "flex",
    flexDirection: "column",
  },
  linkText: {
    fontSize: "12px",
    color: "#0a79a6",
    textDecoration: "underline",
    cursor: "pointer",
  },
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    justifyContent: "center",
    height: "100%",
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "right",
    alignItems: "center",
    gap: "8px",
  },
  percentLabel: {
    fontSize: "14px",
    width: "36px",
    fontWeight: 700,
  },
  titleLabel: {
    fontSize: "14px",
    width: "100px",
    textAlign: "right",
    color: "#6A6A6A",
  },
  infoIcon: {
    fontSize: "14px",
    color: "#9F9F9F",
    cursor: "pointer",
  },
}));

const textDecorationColor = {
  textDecorationColor: "#0A79A6",
};

const SiteCardTwo = ({
  site,
  isTemplatesSite,
  isAdminTemplates,
  onManage,
  onDelete,
  onEdit,
}) => {
  const classes = useStyles();
  const {
    id,
    siteName,
    siteType,
    image,
    description,
    accountId,
    status,
    url,
    slug,
  } = site;
  const webCheckResult =
    site["web_check_result_dev"] || site["web_check_result_external"] || {};
  const domainList = useMemo(() => {
    if (siteType === "Manual") {
      return [url];
    }
    if (status === "pending") return [];
    if (status === "pending-test" || status === "active") {
      return [`http://dev-${slug}.pantheonsite.io`];
    }
    if (status === "pending-production" || status === "test") {
      return [
        `http://dev-${slug}.pantheonsite.io`,
        `http://test-${slug}.pantheonsite.io`,
      ];
    }
    if (status === "production") {
      return [
        `http://dev-${slug}.pantheonsite.io`,
        `http://test-${slug}.pantheonsite.io`,
        `http://live-${slug}.pantheonsite.io`,
      ];
    }
  }, [siteType, slug, status, url]);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const currentUserID = currentAccount?.id || accountId;
  const [selectedCategory, setSelectedCategory] = useState();
  const [siteImage, setSiteImage] = useState("");

  const getChartColor = useCallback((score) => {
    if (score < 0.5) {
      return "error";
    }
    if (score > 0.8) {
      return "success";
    }
    return "warning";
  }, []);

  const chartData = useMemo(() => {
    const categories = [];
    if (webCheckResult?.categories) {
      ["performance", "accessibility", "best-practices", "seo"].forEach(
        (key) => {
          const category = webCheckResult.categories[key];
          categories.push(category);
        }
      );
    }
    return categories;
  }, [webCheckResult?.categories]);

  const handleClickInfo = useCallback(
    (category) => {
      const relatedAudits = category.auditRefs.map((audit) => audit.id);
      const failedAudits = [];
      const passedAudits = [];
      relatedAudits.forEach((audit) => {
        const auditDetail = webCheckResult.audits[audit];
        if (auditDetail?.score === 1) {
          passedAudits.push(auditDetail);
        }
        if (auditDetail?.score < 1) {
          failedAudits.push(auditDetail);
        }
      });
      setSelectedCategory({
        failedAudits,
        passedAudits,
        title: category.title,
      });
    },
    [webCheckResult?.audits]
  );

  useEffect(() => {
    if (image) {
      setSiteImage(image);
    } else {
      const thumbnails =
        webCheckResult?.audits?.["screenshot-thumbnails"]?.details?.items;
      if (thumbnails?.length) {
        setSiteImage(thumbnails[thumbnails.length - 1]?.data);
      } else {
        setSiteImage(defaultTemplateUrl);
      }
    }
  }, [image, webCheckResult?.audits]);

  return (
    <Grid container>
      <Grid item xs>
        <Box className={classes.container}>
          <Box m={2}>
            <img
              alt="logo"
              height="120"
              style={{ objectFit: "cover", width: 160 }}
              src={siteImage}
            />
          </Box>
          <Box className={classes.titleContainer}>
            <Link
              to={`/account/${currentUserID}/site-details/${id}`}
              className={classes.title}
            >
              {siteName}
            </Link>
            {site.siteType === "Manual" && (
              <Box className={classes.badge}>EXTERNAL SITE</Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Grid item xs>
        <Box className={classes.chartContainer}>
          {chartData.map((category) => (
            <Box className={classes.itemContainer} key={category.title}>
              <Typography className={classes.titleLabel}>
                {category.title}
              </Typography>
              <HelpOutlinedIcon
                className={classes.infoIcon}
                onClick={() => handleClickInfo(category)}
              />
              <LinearProgress
                variant="determinate"
                value={category.score * 100}
                color={getChartColor(category.score)}
                sx={{ flex: 1 }}
              />
              <Typography className={classes.percentLabel}>{`${(
                category.score * 100
              ).toFixed(0)}%`}</Typography>
            </Box>
          ))}
        </Box>
      </Grid>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Grid item xs>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Grid
              container
              display="flex"
              direction="row"
              alignItems="center"
              style={{ margin: 12 }}
            >
              <Grid item>
                <Box m={1} className={classes.textPrimary}>
                  {description}
                </Box>
                {!!siteType && (
                  <Box
                    m={1}
                    className={classes.textPrimary}
                  >{`Platform: ${siteType}`}</Box>
                )}
                {domainList.length > 0 && (
                  <Box m={1} className={classes.linkContainer}>
                    {domainList.map((domain) => (
                      <Typography
                        className={classes.linkText}
                        onClick={() => window.open(domain, "_blank")}
                      >
                        {domain}
                      </Typography>
                    ))}
                  </Box>
                )}
                <Box m={1} className={classes.textPrimary}>
                  <Link
                    to={site.domain ? site.domain : null}
                    style={textDecorationColor}
                  >
                    <Box style={{ color: "#0A79A6" }}>
                      {site.domain ? site.domain : null}
                    </Box>
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {!isTemplatesSite && (
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Box mr={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onManage(site.id, accountId)}
                    >
                      Manage
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          {isAdminTemplates && (
            <Grid item xs={6}>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Box mr={3} mt={4}>
                    <Button
                      type="cancel"
                      variant="contained"
                      style={{ backgroundColor: "#F3F6F8", color: "#BD1B00" }}
                      onClick={() => onDelete(site)}
                    >
                      Delete
                    </Button>
                  </Box>
                </Grid>
                <Grid item>
                  <Box mr={3} mt={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onEdit(site.id)}
                    >
                      Edit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      {!!selectedCategory && (
        <PerformanceDetailsModal
          open={!!selectedCategory}
          handleClose={() => setSelectedCategory()}
          title={selectedCategory.title}
          failedAudits={selectedCategory.failedAudits}
          passedAudits={selectedCategory.passedAudits}
        />
      )}
    </Grid>
  );
};

export default SiteCardTwo;
