import React, { useCallback } from "react";
import {
  Paper,
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  planCurrencies,
  planFormErrors,
  planPaymentCycles,
} from "../../../utils/constants";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    padding: 24,
  },
  textField: {
    margin: "12px 0",
  },
}));

const MainForm = ({ plan, setPlan, errors, setErrors }) => {
  const classes = useStyles();

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      setPlan({ ...plan, [name]: value });
    },
    [plan, setPlan]
  );

  const handleBlur = useCallback(
    ({ target: { name, value } }) => {
      let isError = false;
      let message = "";
      if (value.trim().length < 1) {
        isError = true;
        message = planFormErrors[name].message;
      } else {
        isError = false;
        message = "";
      }
      setErrors({ ...errors, [name]: { isError: isError, message: message } });
    },
    [errors, setErrors]
  );

  return (
    <Paper className={classes.container}>
      <Box>
        <TextField
          label="Plan Name*"
          name="name"
          value={plan?.name}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors?.name?.isError}
          helperText={errors?.name?.message}
          className={classes.textField}
        />
        <TextField
          label="Plan Description*"
          name="description"
          value={plan?.description}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors?.description?.isError}
          helperText={errors?.description?.message}
          className={classes.textField}
        />
        <TextField
          label="Plan Price*"
          name="price"
          type="number"
          value={plan?.price}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors?.price?.isError}
          helperText={errors?.price?.message}
          className={classes.textField}
        />
        <FormControl fullWidth className={classes.textField}>
          <InputLabel id="currency">Plan Currency*</InputLabel>
          <Select
            labelId="currency"
            name="currency"
            onChange={handleChange}
            value={plan?.currency}
            label="Plan Currency*"
          >
            {planCurrencies.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="KWALL Payment ID*"
          name="kwallPaymentId"
          value={plan?.kwallPaymentId}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors?.kwallPaymentId?.isError}
          helperText={errors?.kwallPaymentId?.message}
          className={classes.textField}
        />
        <TextField
          label="Stripe Price ID*"
          name="stripePriceId"
          value={plan?.stripePriceId}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors?.stripePriceId?.isError}
          helperText={errors?.stripePriceId?.message}
          className={classes.textField}
        />
        <FormControl fullWidth className={classes.textField}>
          <InputLabel id="paymentCycle">Plan Payment Cycle*</InputLabel>
          <Select
            labelId="paymentCycle"
            name="paymentCycle"
            onChange={handleChange}
            value={plan?.paymentCycle}
            label="Plan Payment Cycle*"
          >
            {planPaymentCycles.map((item) => (
              <MenuItem key={item.label} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Plan Position*"
          name="position"
          type="number"
          value={plan?.position}
          fullWidth
          onChange={handleChange}
          onBlur={handleBlur}
          error={errors?.position?.isError}
          helperText={errors?.position?.message}
          className={classes.textField}
        />
        <TextField
          label="URL"
          name="URL"
          value={plan?.URL}
          fullWidth
          onChange={handleChange}
          className={classes.textField}
        />
      </Box>
    </Paper>
  );
};

export default MainForm;
