import React, { useContext, useEffect, useState } from "react";
import { Stack, Grid, Alert, Box } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import SiteLists from "../../../../components/SiteList";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../../../utils/localStorage";

const SiteList = () => {
  const title = "Starter Kit Sites";
  const history = useHistory();
  const initialSiteState = {
    data: [],
    isLoading: true,
  };
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { accountId } = useParams();
  const [isDeleting, setIsDeleting] = useState(false);

  const [sitesList, setSitesList] = useState(initialSiteState);

  function fetchSitesList() {
    try {
      const sitesCollectionsRef = Firestore.collection(
        `/accounts/${currentAccount.id}/sites`
      ).where("isMyTemplate", "==", true);

      sitesCollectionsRef.onSnapshot((querySnapshot) => {
        const sitesArr = [];
        if (!!querySnapshot) {
          querySnapshot.forEach((doc) => {
            if (
              !!doc.data().plan &&
              (!doc.data().status || doc.data().status !== "deleted")
            ) {
              doc.data().plan.onSnapshot((querySnapshot) => {
                sitesArr.push({
                  id: doc.id,
                  ...doc.data(),
                  ...querySnapshot.data(),
                });
                setSitesList({
                  data: sitesArr,
                  isLoading: false,
                });
              });
            }
          });
        }
        setSitesList({
          data: sitesArr,
          isLoading: false,
        });
      });
    } catch (ex) {
      console.log("___ ex", ex);
    }
  }

  useEffect(() => {
    fetchSitesList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: false,
      },
    ]);
  }, [setBreadcrumb, title, currentAccount.id, currentAccount.name]);

  const handleEdit = (id) => {
    const url = `/account/${accountId}/sites/edit/${id}`;
    history.push({
      pathname: url,
      state: {
        isSiteWithTemplate: true,
      },
    });
  };

  async function deleteFromOrganization(siteId) {
    try {
      const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
      const refOrganization = Firestore.collection("organizations").doc(
        currentOrganization.id
      );

      const updatedTemplates = currentOrganization?.templates?.length
        ? currentOrganization.templates.filter((temp) => temp.siteId !== siteId)
        : [];

      await refOrganization.set({
        ...currentOrganization,
        templates: updatedTemplates,
      });
    } catch (ex) {}
  }

  function getQueryData(snapshots) {
    try {
      let data = {};
      let currentId = "";
      snapshots.forEach((res) => {
        const { id } = res;
        data = { ...data, ...res.data() };
        currentId = id;
      });
      return {
        id: currentId,
        data: data,
      };
    } catch (ex) {
      console.log("___ ex", ex);
    }
  }

  async function deleteSiteUrls(siteId) {
    try {
      const siteUrlsQuery = Firestore.collection("sitesUrls").where(
        "siteId",
        "==",
        siteId
      );
      const organizationSnapshots = await siteUrlsQuery.get();
      const { id } = getQueryData(organizationSnapshots);
      const refSitesUrls = Firestore.collection("sitesUrls").doc(id);
      await refSitesUrls.delete();
      return;
    } catch (ex) {
      console.log("____ ex", ex);
    }
  }

  const handleDeleted = async (site) => {
    try {
      setIsDeleting(true);
      const { templateId, id: siteId } = site;

      deleteFromOrganization(siteId);

      if (templateId.length !== 0) {
        let prevAccountsArr = [];
        const tempRef = await Firestore.collection(
          `/accounts/${accountId}/sites`
        )
          .where("templateId", "==", templateId)
          .get();

        const previousTemplate = await (
          await Firestore.collection("templates").doc(templateId).get()
        ).data();
        if (!tempRef.empty) {
          if (
            !!previousTemplate.isSelected &&
            previousTemplate.isSelected.length > 0
          ) {
            prevAccountsArr = previousTemplate.isSelected.filter(
              (account) => account !== accountId
            );
            const updatePrevRef = await Firestore.collection("templates").doc(
              templateId
            );

            await updatePrevRef.set({
              ...previousTemplate,
              isSelected: prevAccountsArr,
            });
          }
        }
      }

      await Firestore.collection(`/accounts/${accountId}/sites`)
        .doc(siteId)
        .set({ status: "deleted" }, { merge: true });

      Firestore.collection(`/accounts/${accountId}/sites/${siteId}/invoices`)
        .where("siteId", "==", siteId)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((invoice) => {
              invoice.ref.delete();
            });
          }
        })
        .catch((ex) => console.log(ex));

      deleteSiteUrls(siteId);
      displayMessage();
    } catch (ex) {
      console.log("___ ex", ex);
    }
  };

  const handleDelete = async (site) => {
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure you want to delete this? This cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => handleDeleted(site),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const displayMessage = () => {
    setIsDeleting(true);
    setTimeout(() => setIsDeleting(false), 1000);
  };

  return (
    <Stack spacing={3}>
      {isDeleting && <Alert severity="error">Deleted Successfully</Alert>}
      <Grid container>
        <Grid item xs={12}>
          <Alert
            variant="outlined"
            severity="warning"
            style={{ color: "#6A6A6A" }}
          >
            <Box
              style={{
                color: "#6A6A6A",
                fontStyle: "Roboto",
                fontWeight: 700,
                fontSize: "14px",
              }}
            >
              Warning:{" "}
              <span
                style={{
                  color: "#6A6A6A",
                  fontStyle: "Roboto",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                Starter Kits have full functionality except for they can't go
                live on a domain. If you want to change these, change your
                subscription option in the site to a non-template option.
              </span>
            </Box>
          </Alert>
        </Grid>
      </Grid>
      <SiteLists
        isTemplatesSite
        isAdminTemplates
        sitesList={sitesList}
        onDelete={handleDelete}
        onEdit={handleEdit}
      />
    </Stack>
  );
};

export default SiteList;
