import { Button, Hidden, Link, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    margin: 12,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      margin: "12px 0",
    },
  },
  image: {
    height: 160,
    width: 160,
    objectFit: "contain",
    padding: 10,
  },
  content: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 12,
    gap: 8,
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
  },
  title: {
    fontSize: "1.3rem",
    color: "black",
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  provider: {
    fontSize: "1rem",
    color: "#6a6a6a",
    fontWeight: 600,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
  description: {
    fontSize: "0.9rem",
    maxHeight: 130,
    [theme.breakpoints.down("md")]: {
      fontSize: "0.7rem",
    },
  },
  btnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "12px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
    },
  },
  button: {
    width: 120,
    margin: 6,
  },
}));

export const ServiceEditCard = ({ service, handleDelete }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleEdit = useCallback(() => {
    history.push(`/serviceLists/edit/${service.id}`);
  }, [service, history]);

  return (
    <Paper className={classes.container}>
      <Hidden mdDown>
        <Box
          component="img"
          alt="Service Image"
          src={service.imageUrl}
          className={classes.image}
        />
      </Hidden>

      <Box className={classes.content}>
        <Typography className={classes.title}>{service.name}</Typography>
        <Typography className={classes.provider}>
          {`Service by ${service.provider}`}
        </Typography>
        <Typography className={classes.description}>
          {service.description}
        </Typography>
        {service.link && (
          <Link
            href={service.link}
            target="_blank"
            className={classes.description}
          >
            Learn More
          </Link>
        )}
      </Box>
      <Box className={classes.btnContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleEdit}
          className={classes.button}
        >
          Edit
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={handleDelete}
          className={classes.button}
        >
          Delete
        </Button>
      </Box>
    </Paper>
  );
};
