import React, { useMemo } from "react";
import { Box } from "@mui/material";
import logo from "../../images/color-logo.png";

const Logo = ({ organization }) => {
  const logoUrl = useMemo(() => {
    return organization?.imageUrl || logo;
  }, [organization]);
  return (
    <Box>
      <Box
        component="img"
        src={logoUrl}
        alt="logo"
        sx={{ backgroundColor: "#FFFFFF", width: "2.5rem" }}
      />
    </Box>
  );
};

export default Logo;
