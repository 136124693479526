import React from "react";
import { Container } from "@mui/material";
import UserPageLayout from "../../../components/user/UserPageLayout";
import UserOrganizationView from "../../../components/user/UserOrganizationView";

const Organization = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: 130, height: "100vh" }}>
      <UserPageLayout title="Manage Your Organization">
        <UserOrganizationView />
      </UserPageLayout>
    </Container>
  );
};

export default Organization;
