import { Button, Typography, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "#fafafa",
    padding: 8,
    borderRadius: 4,
    gap: 12,
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
  },
  image: {
    height: 60,
    width: 60,
    objectFit: "contain",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontSize: "1.3rem",
    color: "black",
  },
  provider: {
    fontSize: "1rem",
    color: "#6a6a6a",
    fontWeight: 600,
  },
  description: {
    fontSize: "0.9rem",
    flex: 1,
    marginTop: 12,
    overflow: "hidden",
  },
  button: {
    width: 120,
  },
  priceLabel: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#407543",
  },
  lowestLabel: {
    fontSize: "12px",
  },
  estimationLabel: {
    backgroundColor: "#dcdbdc",
    fontSize: "14px",
    borderRadius: "4px",
    width: "fit-content",
    padding: "0 8px",
  },
}));

export const ServiceCardMobile = ({ service }) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();
  const [isRequested, setIsRequested] = useState(false);

  const handleClickRequest = useCallback(() => {
    history.push(`/account/${params.accountId}/service/${service.id}`, {
      siteId: params.siteId,
    });
  }, [service, history, params]);

  useEffect(() => {
    if (
      service.requested &&
      service.requested.length > 0 &&
      service.requested.includes(params.siteId)
    ) {
      setIsRequested(true);
    }
  }, [params, service]);
  return (
    <Box className={classes.container}>
      <Box className={classes.rowContainer}>
        <Box
          component="img"
          alt="Service Image"
          src={service.imageUrl}
          className={classes.image}
        />
        <Box className={classes.columnContainer}>
          <Typography className={classes.estimationLabel}>
            {service.estimatedTime}
          </Typography>
          <Typography className={classes.title}>{service.name}</Typography>
          <Typography className={classes.provider}>
            {`Service by ${service.provider}`}
          </Typography>
        </Box>
      </Box>
      <Typography className={classes.description}>
        {service.description}
      </Typography>
      {service.link && (
        <Link
          href={service.link}
          target="_blank"
          className={classes.description}
        >
          Learn More
        </Link>
      )}
      <Box className={classes.rowContainer}>
        <Button
          variant="contained"
          size="small"
          className={classes.button}
          onClick={handleClickRequest}
          disabled={isRequested}
        >
          {isRequested ? "Requested" : "Request"}
        </Button>
        {!service.price ? (
          <Typography className={classes.priceLabel}>
            {service.price}
          </Typography>
        ) : (
          service.lowestPrice && (
            <Box className={classes.columnContainer}>
              <Typography className={classes.lowestLabel}>
                Starting at
              </Typography>
              <Typography className={classes.priceLabel}>
                ${service.lowestPrice}
              </Typography>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
};
