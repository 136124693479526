import * as React from "react";
import { Box, Card, CardContent, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  textOne: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Roboto",
    color: "#6A6A6A",
    margin: "10px 0px",
  },
  textTwo: {
    fontSize: 22,
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  subscription: {
    color: "##1E1E1E",
  },
  others: {
    color: "#6A6A6A",
  },
  btnSubscription: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
  },
  btnInvoiceDetails: {
    fontFamily: "Roboto",
    fontSize: 14,
    fontWeight: 400,
    width: "10.3rem",
  },
}));

const SubscriptionDetailsCard = ({ planData }) => {
  const params = useParams();
  const history = useHistory();
  const classes = useStyles();
  const { name } = planData;

  const handleManageSubscriptions = () => {
    const { accountId, siteId } = params;

    const url = `/account/${accountId}/billing/plan/${siteId}`;
    history.push({
      pathname: url,
      isEditing: true,
    });
  };

  const handleMoveToInvoice = () => {
    const { accountId, siteId } = params;
    const url = `/account/${accountId}/monthly-invoices-details/${siteId}`;
    history.push(url);
  };

  return (
    <Card sx={{ display: "flex", height: 200, overflow: "auto" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }} mb={3}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Box className={`${classes.textTwo} ${classes.subscription}`}>
            Subscription
          </Box>
          <Box className={classes.textOne}>{`Plan: ${name}`}</Box>
          <Box>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleManageSubscriptions}
            >
              <Box className={classes.btnSubscription}>Manage Subscription</Box>
            </Button>
          </Box>
          <Box mt={2}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleMoveToInvoice}
            >
              <Box className={classes.btnInvoiceDetails}>
                Show Invoice Detail
              </Box>
            </Button>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default SubscriptionDetailsCard;
