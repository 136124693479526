import React, { useCallback, useContext, useEffect, useState } from "react";
import UserMenu from "../menus/UserMenu";
import AppMenu from "../menus/AppMenu";
import Layout from "../Layout";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../utils/localStorage";
import { AuthContext } from "../FirebaseAuth";
import { Firestore } from "../FirebaseAuth/firebase";
import { Typography } from "@mui/material";
import Loader from "../Loader";

const AppTemplate = ({ children, role }) => {
  const { authUser } = useContext(AuthContext);
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);

  const checkKwallAdmin = useCallback(async () => {
    const userRef = await Firestore.collection("users")
      .doc(authUser.user.uid)
      .get();
    if (!userRef.data()?.role?.includes("kwallAdmin")) {
      setError("Permission denied.");
    }
    setLoading(false);
  }, [authUser.user.uid]);

  const checkAdmin = useCallback(async () => {
    const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
    const userRef = await Firestore.collection("users")
      .doc(authUser.user.uid)
      .get();
    if (
      !currentOrganization?.admins?.includes(authUser.user.uid) &&
      !userRef.data()?.role?.includes("kwallAdmin")
    ) {
      setError("Permission denied.");
    }
  }, [authUser.user.uid]);

  useEffect(() => {
    if (role === "kwallAdmin") {
      setLoading(true);
      checkKwallAdmin();
    }

    if (role === "admin") {
      checkAdmin();
    }
  }, [authUser, checkAdmin, checkKwallAdmin, role]);

  return (
    <Layout drawerMenu={<AppMenu />} toolBarMenu={<UserMenu />}>
      {loading ? (
        <Loader text="Loading..." />
      ) : error ? (
        <Typography
          sx={{ width: "100%", height: "100vh", textAlign: "center" }}
        >
          {error}
        </Typography>
      ) : (
        children
      )}
    </Layout>
  );
};

export default AppTemplate;
