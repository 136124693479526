import React, { useContext, useEffect } from "react";
import { Box, Container, Paper } from "@mui/material";
import { BreadcrumbContext } from "../Breadcrumb";
import {
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
} from "../../utils/localStorage";
import { isKWALLAdmin } from "../../utils";

const OrganizationPageLayout = (props) => {
  const { title, children } = props;
  const currentUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const path = isKWALLAdmin(currentUser?.role)
    ? "/organization_list"
    : "/organization/switch-organization";
  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: path,
        text: "Organizations",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [path, setBreadcrumb, title]);

  return (
    <Container>
      <Paper>
        <Box p={2}>{children}</Box>
      </Paper>
    </Container>
  );
};

export default OrganizationPageLayout;
