import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  Alert,
  Paper,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Avatar,
  IconButton,
  Typography,
  Stack,
  Box,
  OutlinedInput,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useParams } from "react-router";

import DataEdit from "../../../components/DataEdit";
import Loader from "../../../components/Loader";
import {
  Firestore,
  FirebaseStorage,
} from "../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from "../../../components/Breadcrumb";
import { formSchema } from "./template.json";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { accountTypesArr } from "../../../utils/constants";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Input = styled("input")({
  display: "none",
  width: "100%",
});

const TemplateEdit = () => {
  const mountedRef = useRef(true);
  const theme = useTheme();
  const title = "Edit Template";
  const siteTypesArr = ["Drupal", "Wordpress"];

  const { tempId } = useParams();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [templateData, setTemplateData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [nameError, setNameError] = useState(null);
  const [descriptionError, setDescriptionError] = useState(null);
  const [URL, setURL] = useState("");
  const [templateIdError, setTemplateIdError] = useState(null);
  const [selectedAccountTypes, setSelectedAccountTypes] = useState([]);
  const [organizationID, setOrganizationID] = useState([]);
  const [organizationIds, setOrganizationIds] = useState([]);
  const [excludeOrganizationID, setExcludeOrganizationID] = useState([]);
  const [customerIds, setCustomerIds] = useState([]);
  const [customerID, setCustomerID] = useState([]);
  const [excludeCustomerID, setExcludeCustomerID] = useState([]);
  const [isUploading, setIsUploading] = useState(false);

  const validate = () => {
    return !nameError && !descriptionError && !templateIdError;
  };

  const getOrganizationIds = useCallback(async () => {
    const snapShots = await Firestore.collection("organizations").get();
    const ids = [];
    snapShots.forEach((doc) => {
      ids.push(doc.id);
    });
    setOrganizationIds(ids);
  }, []);

  const getCustomerIds = useCallback(async () => {
    const snapShots = await Firestore.collection("accounts").get();
    const ids = [];
    snapShots.forEach((doc) => {
      ids.push(doc.id);
    });
    setCustomerIds(ids);
  }, []);

  const fetchSingleTemplate = useCallback(async () => {
    const siteRef = Firestore.collection("templates").doc(tempId);

    const data = (await siteRef.get()).data();
    if (!mountedRef.current) return null;
    const { accountType } = data;
    setSelectedAccountTypes(accountType);
    setTemplateData(data);
    setURL(data.URL || "");
    setOrganizationID(data.organizationID);
    setExcludeOrganizationID(data.excludeOrganizationID);
    setCustomerID(data.customerID);
    setExcludeCustomerID(data.excludeCustomerID);
    setIsLoading(false);
  }, [tempId]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: "/global-templates-list",
        text: "Templates",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  useEffect(() => {
    getCustomerIds();
    getOrganizationIds();
    fetchSingleTemplate();

    return () => {
      mountedRef.current = false;
    };
  }, [getCustomerIds, getOrganizationIds, fetchSingleTemplate]);

  const handleEditSite = useCallback(
    async (id, data) => {
      try {
        let finalObj = {
          ...data,
          image: templateData.image,
          URL: URL,
          siteCloneId: data.siteId,
          createdAt: Date.now(),
          accountType: selectedAccountTypes,
          isGlobalTemplate: true,
          organizationID,
          excludeOrganizationID,
          customerID,
          excludeCustomerID,
        };

        const updateRef = Firestore.collection("templates").doc(id);
        await updateRef.set({ ...finalObj });
        return true;
      } catch (ex) {
        console.log("___ ex", ex);
      }
    },
    [
      URL,
      customerID,
      templateData,
      excludeCustomerID,
      excludeOrganizationID,
      organizationID,
      selectedAccountTypes,
    ]
  );

  const handleUpload = useCallback(async (image) => {
    try {
      const downloadUrl = await FirebaseStorage.ref(
        `templates/${image.name}`
      ).put(image);
      return downloadUrl.ref.getDownloadURL();
    } catch (ex) {
      console.log("ex", ex);
    }
  }, []);

  const handleChangeImage = useCallback(
    async (event) => {
      event.preventDefault();
      setIsUploading(true);
      if (!!event.target.files[0]) {
        const uploadUrl = await handleUpload(event.target.files[0]);
        setTemplateData((prev) => ({ ...prev, image: uploadUrl }));
      }
      setIsUploading(false);
    },
    [handleUpload]
  );

  return (
    <>
      {isLoading ? (
        <Paper>
          <Box p={2}>
            <Loader text="Loading..." />
          </Box>
        </Paper>
      ) : (
        <DataEdit
          id={tempId}
          schema={formSchema}
          validation={validate}
          success={<Alert severity="success">Updated Successfully.</Alert>}
          handleEdit={handleEditSite}
        >
          <TextField
            label="Template Name"
            name="name"
            defaultValue={templateData.name}
            fullWidth
            onBlur={(e) => {
              if (
                e.target.value.trim().length < 1 ||
                e.target.value.trim().length > 100
              ) {
                setNameError(
                  "Template Name must be between 1 to 100 characters."
                );
              } else {
                setNameError(null);
              }
            }}
            error={nameError ? true : false}
            helperText={nameError}
          />
          <TextField
            label="Template Description"
            name="description"
            defaultValue={templateData.description}
            fullWidth
            onBlur={(e) => {
              if (
                e.target.value.trim().length < 1 ||
                e.target.value.trim().length > 100
              ) {
                setDescriptionError(
                  "Template Description must be between 1 to 100 characters."
                );
              } else {
                setDescriptionError(null);
              }
            }}
            error={descriptionError ? true : false}
            helperText={descriptionError}
          />
          <TextField
            label="Site ID"
            name="siteId"
            defaultValue={templateData.siteId}
            fullWidth
          />
          <TextField
            label="Template ID"
            name="templateId"
            defaultValue={templateData.templateId}
            fullWidth
            onBlur={(e) => {
              if (
                e.target.value.trim().length < 1 ||
                e.target.value.trim().length > 50
              ) {
                setTemplateIdError(
                  "Template ID must be between 1 to 50 characters."
                );
              } else {
                setTemplateIdError(null);
              }
            }}
            error={templateIdError ? true : false}
            helperText={templateIdError}
          />
          <TextField
            label="URL"
            name="URL"
            fullWidth
            defaultValue={templateData.URL}
            onChange={(e) => setURL(e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel id="accountTypeGlobal">Account Type</InputLabel>
            <Select
              labelId="accountTypeGlobal"
              id="accountTypeGlobal"
              name="accountTypeGlobal"
              multiple
              fullWidth
              value={selectedAccountTypes}
              onChange={(event) =>
                setSelectedAccountTypes(
                  typeof event.target.value === "string"
                    ? event.target.value.split(",")
                    : event.target.value
                )
              }
              input={<OutlinedInput label="Account Type" />}
              MenuProps={MenuProps}
            >
              {accountTypesArr.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, selectedAccountTypes, theme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="siteType">Site Type</InputLabel>
            <Select
              labelId="siteType"
              name="siteType"
              onChange={(e) =>
                setTemplateData((prev) => ({
                  ...prev,
                  siteType: e.target.value,
                }))
              }
              value={templateData.siteType}
              label="Site Type"
            >
              {siteTypesArr.map((siteType) => (
                <MenuItem key={siteType} value={siteType}>
                  {siteType}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="customerID">Customer ID</InputLabel>
            <Select
              labelId="customerID"
              name="customerID"
              multiple
              onChange={(e) => setCustomerID(e.target.value)}
              value={customerID || []}
              label="Customer ID"
            >
              {customerIds.map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="excludeCustomerID">Exclude Customer ID</InputLabel>
            <Select
              labelId="excludeCustomerID"
              name="excludeCustomerID"
              onChange={(e) => setExcludeCustomerID(e.target.value)}
              value={excludeCustomerID || []}
              multiple
              label="Exclude Customer ID"
            >
              {customerIds.map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="organizationID">Organization ID</InputLabel>
            <Select
              labelId="organizationID"
              name="organizationID"
              multiple
              onChange={(e) => setOrganizationID(e.target.value)}
              value={organizationID || []}
              label="Organization ID"
            >
              {organizationIds.map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="excludeOrganizationID">
              Exclude Organization ID
            </InputLabel>
            <Select
              labelId="excludeOrganizationID"
              name="excludeOrganizationID"
              onChange={(e) => setExcludeOrganizationID(e.target.value)}
              value={excludeOrganizationID || []}
              multiple
              label="Exclude Customer ID"
            >
              {organizationIds.map((id) => (
                <MenuItem key={id} value={id}>
                  {id}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isUploading ? (
              <Loader />
            ) : (
              <>
                <Avatar src={templateData.image || ""} alt="Remy Sharp" />
                <Stack direction="row" alignItems="center">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      name="image"
                      type="file"
                      onChange={(e) => handleChangeImage(e)}
                    />
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                    >
                      <CloudUploadIcon />{" "}
                      <Box>
                        <Typography ml={1}>Upload Image</Typography>
                      </Box>
                    </IconButton>
                  </label>
                </Stack>
              </>
            )}
          </Box>
        </DataEdit>
      )}
    </>
  );
};

export default TemplateEdit;
