import {
  CloudFunctions,
  FirebaseAuth,
} from "../../components/FirebaseAuth/firebase";
import { log, SIGN_IN, SIGN_OUT, UPDATE_USERNAME } from "../log";

export const userSignIn = async (callback) => {
  var dt = new Date();

  const Firestore = FirebaseAuth.firestore();
  const currentUser = FirebaseAuth.auth().currentUser;
  const organizationSnapShots = await Firestore.collection("organizations")
    .orderBy("name", "asc")
    .get();
  let relatedOrganization;
  organizationSnapShots.forEach((doc) => {
    if (
      doc.data().primaryDomain &&
      currentUser.email.includes(doc.data().primaryDomain)
    ) {
      relatedOrganization = { ...doc.data(), id: doc.id };
    }
  });

  if (relatedOrganization) {
    const organizationRef = Firestore.collection("organizations").doc(
      relatedOrganization.id
    );
    if (relatedOrganization.autoEnroll) {
      await organizationRef.update({
        access: [...relatedOrganization.access, currentUser.uid],
      });
    } else {
      if (relatedOrganization.pending) {
        await organizationRef.update({
          pending: [...relatedOrganization.pending, currentUser.uid],
        });
      } else {
        await organizationRef.update({
          pending: [currentUser.uid],
        });
      }
      const sendPendingUserAllowEmail = CloudFunctions.httpsCallable(
        "organizations-sendPendingUserAllowEmail"
      );
      await sendPendingUserAllowEmail({
        organizationId: relatedOrganization.id,
        username: currentUser.displayName,
        email: currentUser.email,
      });
    }
  }

  const userDocRef = Firestore.collection("users").doc(currentUser.uid);
  const userData = await userDocRef.get();
  if (!userData.exists) {
    const sendSlackNotification = CloudFunctions.httpsCallable(
      "kwallCloud-sendSlackEmailForNewRegister"
    );
    await sendSlackNotification({
      displayName: currentUser.displayName,
      photoURL: currentUser.photoURL,
      emailAddress: currentUser.email,
      creationTime: dt,
      lastLoginTime: dt,
    });
  }
  userDocRef
    .set(
      {
        displayName: currentUser.displayName || "Accorbis User",
        photoURL: currentUser.photoURL,
        emailAddress: currentUser.email,
        creationTime: dt,
        lastLoginTime: dt,
      },
      { merge: !!userData.exists }
    )
    .then(() => {
      callback(true);
    })
    .catch((err) => {
      console.log(err);
      callback(false);
    });
  log(SIGN_IN);
};

export const userSignOut = () => {
  log(SIGN_OUT, (result) => {
    // wait for log is successfully written before signing out
    if (result) {
      FirebaseAuth.auth().signOut();
    }
  });
};

export const userUpdateName = () => {
  const Firestore = FirebaseAuth.firestore();
  const currentUser = FirebaseAuth.auth().currentUser;

  const userDocRef = Firestore.collection("users").doc(currentUser.uid);
  userDocRef.set(
    {
      displayName: currentUser.displayName || "Accorbis User",
    },
    { merge: true }
  );
  log(UPDATE_USERNAME);
};
