import React from "react";
import { Box, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: 400,
    padding: 12,
    border: "2px solid #B8B8B8",
    boxSizing: "border-box",
    borderRadius: 4,
    position: "relative",
  },
  btnCreate: {
    width: "fit-content",
    position: "absolute",
    bottom: 16,
  },
}));

const EnvironmentCreateCard = ({ envName, onCreateEnvironment }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box
        style={{
          fontSize: 22,
          fontWeight: 400,
          fontFamily: "Roboto",
          color: "#1E1E1E",
        }}
      >
        {`Create ${envName} Environment`}
      </Box>
      <Box
        style={{
          fontSize: 14,
          fontWeight: 400,
          fontFamily: "Roboto",
          color: "#6A6A6A",
          marginTop: 13,
        }}
      >
        You can test out code and content here, and it's a great place to review
        iterative updates to the codebase and content.
      </Box>
      <Button
        color="primary"
        variant="outlined"
        onClick={onCreateEnvironment}
        className={classes.btnCreate}
      >
        {`Create ${envName}`}
      </Button>
    </Box>
  );
};

export default EnvironmentCreateCard;
