import React, { useContext, useEffect, useState } from "react";
import { Stack, Button, Alert, Paper, Box } from "@mui/material";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import { BreadcrumbContext } from "../../../components/Breadcrumb";
import { Firestore } from "../../../components/FirebaseAuth/firebase";
import MaterialTable from "../../../components/MaterialTable";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "description", label: "Description", minWidth: 170 },
  { id: "siteType", label: "Site Type", minWidth: 170 },
];

const TemplateList = () => {
  const title = "Templates";
  const history = useHistory();
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const [templatesList, setTemplatesList] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);

  function fetchSitesList() {
    const sitesCollectionsRef = Firestore.collection("templates");
    sitesCollectionsRef.onSnapshot((querySnapshot) => {
      const sitesArr = [];
      querySnapshot.forEach((doc) => {
        sitesArr.push({ id: doc.id, ...doc.data() });
      });
      setTemplatesList(sitesArr);
    });
  }

  useEffect(() => {
    fetchSitesList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  const displayMessage = () => {
    setIsDeleted(true);
    setTimeout(() => setIsDeleted(false), 1000);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure you want to delete this? This cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => handleDeletionTemplate(id),
        },
        {
          label: "No",
          onClick: () => console.log("___ NO"),
        },
      ],
    });
  };

  const handleDeletionTemplate = async (id) => {
    try {
      await Firestore.collection("templates").doc(id).delete();
      displayMessage();
    } catch (ex) {
      console.log("___ ex", ex);
    }
  };

  const handleEdit = (id) => {
    const url = `/global-template-edit/${id}`;
    history.push(url);
  };

  return (
    <Stack spacing={3}>
      {isDeleted && <Alert severity="error">Deleted Successfully</Alert>}
      <div style={{ marginLeft: "auto" }}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            onClick={() => history.push(`/global-template-create`)}
          >
            Add Template
          </Button>
        </Stack>
      </div>
      <Paper>
        <Box>
          <MaterialTable
            columns={columns}
            rows={templatesList}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
        </Box>
      </Paper>
    </Stack>
  );
};

export default TemplateList;
