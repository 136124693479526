import React, { useCallback } from "react"
import { Box, Button, Card, Grid, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(() => ({
    planCard: {
        width: "100%",
        marginBottom: 12,
        padding: "16px 24px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        minHeight: 120
    },
    flexGrid: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    btnContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
    },
    button: {
        margin: "4px 0",
    }
}));

export const PlanItemCard = ({ plan, handleDelete }) => {

    const classes = useStyles();
    const history = useHistory();
    const handleClickEdit = useCallback(() => {
        history.push(`/planLists/edit/${plan.id}`);
    }, [history, plan]);

    return (
        <Card className={classes.planCard}>
            <Grid container>
                <Grid item xs={12} md={6} className={classes.flexGrid} padding={1}>
                    <Box display="flex" flexDirection="row" alignItems="center">
                        <Typography variant="h6" marginRight="4px">
                            {plan.name}
                        </Typography>
                        <Typography variant="h6" color="primary">
                            {`($ ${plan.price}${plan.paymentCycle === "mo" && "/Month"})`}
                        </Typography>
                    </Box>
                    <Typography variant="body">
                        {plan.description}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4} className={classes.flexGrid}>
                    {plan?.features && plan.features.map((feature, key) => {
                        return (
                            <Typography variant="body" color="primary" key={key} margin={1}>
                                {`• ${feature}`}
                            </Typography>
                        )
                    })}
                </Grid>
                <Grid item xs={12} md={2} className={classes.btnContainer}>
                    <Button variant="contained" color="primary" onClick={handleClickEdit} className={classes.button}>
                        Edit
                    </Button>
                    <Button variant="contained" color="error" onClick={handleDelete} className={classes.button}>
                        Delete
                    </Button>
                </Grid>
            </Grid>
        </Card>
    )
}