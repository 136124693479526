import { Box, Link, Typography } from "@mui/material";
import { memo } from "react";
import Logo from "../Logo";

export const AuthTemplate = memo(({ children }) => {
  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          height: "100vh",
          padding: "120px 24px",
          maxWidth: "600px",
        }}
      >
        <Box>
          <Typography variant="body1" style={{ marginBottom: 16 }}>
            Welcome to the site management portal. Website Management & Intelligence Platform.
          </Typography>
          <Logo size="80px" />
        </Box>
        {children}
        <Typography>
          By continuing, you are indicating that you accept the{" "}
          <Link
            href="https://docs.google.com/document/d/13TDfx8W7M79Ub6veRsYD-PusZNEXH4jNP5Ff3LUptWY"
            target="_blank"
          >
            Terms of service
          </Link>{" "}
          and{" "}
          <Link
            href="https://www.kwallcompany.com/privacy-policy/"
            target="_blank"
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      </Box>
    </Box>
  );
});
