import { Box, Button, Typography } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import firebase from "firebase/app";
import { Email } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { userSignIn } from "../../../libs/user";
import {
  LOCAL_STORAGE_INVITE_CODE,
  getLocalStorage,
} from "../../../utils/localStorage";

const Welcome = memo(() => {
  const history = useHistory();
  const [errors, setErrors] = useState();

  const handleLoginWithGoogle = useCallback(
    async (event) => {
      event.preventDefault();
      const googleProvider = new firebase.auth.GoogleAuthProvider();
      googleProvider.setCustomParameters({ prompt: "select_account" });
      try {
        const result = await firebase.auth().signInWithPopup(googleProvider);
        await firebase.auth().signInWithCredential(result.credential);
        userSignIn((res) => {
          if (res) {
            const inviteCode = getLocalStorage(LOCAL_STORAGE_INVITE_CODE);
            if (inviteCode) {
              history.push(`/invite/${inviteCode}`);
            } else {
              history.push("/");
            }
          }
        });
      } catch (error) {
        setErrors(error.message);
      }
    },
    [history]
  );
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        alignItems: "center",
        margin: "24px 0",
      }}
    >
      <Typography variant="h6">
        Please sign in to login or create your account.
      </Typography>
      <Button
        variant="contained"
        startIcon={<Email />}
        color="error"
        onClick={() => history.push("signin")}
        style={{
          textTransform: "none",
          width: "220px",
          justifyContent: "flex-start",
          gap: "16px",
        }}
      >
        Sign in with email
      </Button>
      <Button
        variant="contained"
        startIcon={
          <img
            alt="Google"
            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
            style={{ width: "18px" }}
          />
        }
        style={{
          textTransform: "none",
          width: "220px",
          backgroundColor: "white",
          color: "#757575",
          justifyContent: "flex-start",
          gap: "16px",
        }}
        onClick={(event) => handleLoginWithGoogle(event)}
      >
        Sign in with Google
      </Button>
      {errors && (
        <Typography variant="body2" style={{ color: "#d32f2f" }}>
          {errors}
        </Typography>
      )}
    </Box>
  );
});

export default Welcome;
