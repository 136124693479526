import React, { useContext, useEffect, useState, useCallback } from "react";
import { Stack, Button } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";

import DataList from "../../../../components/DataList";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import { ListInvoicesApi } from "./InvoiceApi";
import { listResponse } from "./invoice.json";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";

const ActionButtons = () => {
  const history = useHistory();
  const params = useParams();
  const { accountId, siteId } = params;

  const url = `/account/${accountId}/invoice-details/${siteId}`;

  return (
    <Stack direction="row" spacing={1} mt={2}>
      <Button variant="contained" onClick={() => history.push(url)}>
        Invoice Detail
      </Button>
    </Stack>
  );
};

const MonthlyInvoiceList = () => {
  const title = "Monthly Invoices Details";
  const params = useParams();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [templatesList, setTemplatesList] = useState([]);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);

  function fetchInvoicesList() {
    const { accountId, siteId } = params;
    const invoiceCollectionsRef = Firestore.collection(
      `accounts/${accountId}/sites/${siteId}/invoices`
    );

    invoiceCollectionsRef.onSnapshot((querySnapshot) => {
      const sitesArr = [];
      querySnapshot.forEach((doc) => {
        sitesArr.push({ id: doc.id, ...doc.data() });
      });
      setTemplatesList(sitesArr);
    });
  }

  useEffect(() => {
    fetchInvoicesList();
    //eslint-disable-next-line
  }, []);

  const handleFetch = useCallback(
    (page, pageSize) => {
      return new Promise((resolve, reject) => {
        // apply custom filter here if you wish to pass additional parameters to the api calls
        ListInvoicesApi(templatesList, page, pageSize)
          .then((images) => {
            let records = [];
            // loop through the data to add the visual components in to the list
            for (let i = 0; i < images.data.length; i++) {
              const record = {
                id: images.data[i].id,
                amountDue: images.data[i].amountDue,
                amountPaid: images.data[i].amountPaid,
                total: images.data[i].total,
                action: (
                  <ActionButtons
                    id={images.data[i].id}
                    isSelected={images.data[i].isSelected}
                  />
                ),
              };
              records.push(record);
            }
            resolve({
              total: images.total,
              data: records,
            });
          })
          .catch((err) => {
            reject(err);
          });
      });

      //eslint-disable-next-line
    },
    [templatesList]
  );

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/site-details/${params.siteId}`,
        text: "Site Details",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, currentAccount, params]);

  return (
    <Stack spacing={3}>
      <DataList handleFetch={handleFetch} schema={listResponse} />
    </Stack>
  );
};

export default MonthlyInvoiceList;
