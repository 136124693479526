import { Button, Hidden, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import ApartmentIcon from "@mui/icons-material/Apartment";
import { useDownMedium } from "../../utils/useScreenSize";
import { isAdmin, isKWALLAdmin, isOrgAdmin } from "../../utils";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
  setLocalStorage,
} from "../../utils/localStorage";
import "./OrganizationItem.css";
import { useContext } from "react";
import { AuthContext } from "../../components/FirebaseAuth";

export const OrganizationItem = ({ organization }) => {
  const history = useHistory();
  const isDownMd = useDownMedium();
  const currentUser = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);
  const { authUser } = useContext(AuthContext);

  const handleManageOrganization = useCallback(() => {
    setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, organization);
    history.push(`/organization_list/detail/${organization.id}`);
  }, [organization, history]);

  const handleSelectOrganization = useCallback(() => {
    setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, organization);
    history.push("/");
  }, [organization, history]);

  const handleManageSites = useCallback(() => {
    setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, organization);
    history.push(`/organization_list/sites/${organization.id}`);
  }, [organization, history]);

  const handleManageUsers = useCallback(() => {
    setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, organization);
    history.push(`/organization/${organization.id}/users/`);
  }, [organization, history]);

  return (
    <Paper className="organizationItem">
      {organization.imageUrl && !isDownMd ? (
        <Box
          component="img"
          alt="Organization Avatar"
          src={organization.imageUrl}
          className={"organizationItem__image"}
        />
      ) : (
        <ApartmentIcon className={"organizationItem__image"} />
      )}
      <Box className={"organizationItem__content istitle"}>
        <Typography className={"organizationItem__title"}>
          {organization.name}
        </Typography>
      </Box>
      <Box className={"organizationItem__content isContent"}>
        {organization.organizationType && (
          <Typography className={"organizationItem__provider"}>
            <span>Organization Type:</span> {organization.organizationType}
          </Typography>
        )}
        {organization.accountType && (
          <Typography className={"organizationItem__provider"}>
            <span>Account Type:</span> {organization.accountType}
          </Typography>
        )}
        <Typography className={"organizationItem__provider"}>
          <span>Total Account:</span> {organization.accounts?.length}
        </Typography>
        <Hidden smDown>
          <Typography className={"organizationItem__description"}>
            {organization.description}
          </Typography>
        </Hidden>
      </Box>
      <Box className={"organizationItem__btnContainer"}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSelectOrganization}
          className={"organizationItem__button"}
          size={isDownMd ? "small" : "small"}
        >
          Set as Active Organization
        </Button>
      </Box>
      {(isAdmin(currentUser?.role) ||
        isOrgAdmin(currentUser?.role) ||
        isKWALLAdmin(currentUser?.role) ||
        organization.admins?.includes(authUser.user.uid)) && (
        <Box className={"organizationItem__btnContainer"}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleManageOrganization}
            className={"organizationItem__button"}
            size={isDownMd ? "small" : "small"}
          >
            Manage Organization
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleManageSites}
            className={"organizationItem__button"}
            size={isDownMd ? "small" : "small"}
          >
            Manage Sites
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleManageUsers}
            className={"organizationItem__button"}
            size={isDownMd ? "small" : "small"}
          >
            Manage Users
          </Button>
        </Box>
      )}
    </Paper>
  );
};
