import { useMediaQuery } from "@mui/material";

export const useDownLarge = () =>
  useMediaQuery((theme) => theme.breakpoints.down("lg"));

export const useDownMedium = () =>
  useMediaQuery((theme) => theme.breakpoints.down("md"));

export const useDownSmall = () =>
  useMediaQuery((theme) => theme.breakpoints.down("sm"));
