import React, { useEffect, useContext, useState } from 'react';
import { Box, Grid, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import { Firestore } from '../../../../components/FirebaseAuth/firebase';
import SiteForm from '../../../../components/SiteForm';

const AddSite = () => {
    const title = 'Add New Site'
    const { setBreadcrumb } = useContext(BreadcrumbContext);

    const [disabled, setDisabled] = useState();
    const history = useHistory();

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/sites/lists",
                text: "Sites",
                active: false
            },
            {
                to: "/sites/add-new",
                text: "Add New",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb]);

    const handleSubmit = async (data, id) => {
        try {
            setDisabled(true);
            if (!!id) {
                const updateRef = Firestore.collection('sites').doc(id);
                await updateRef.set({ ...data });
                setDisabled(false);
                history.push('/sites/lists');
                toast.info('Updated Successfully');
                return;
            }

            let sitesCollectionRef = Firestore.collection('sites').doc();
            await sitesCollectionRef.set({
                ...data
            });
            setDisabled(false);
            history.push('/sites/lists');
            toast.success('Added Successfully');

        } catch (ex) {
            setDisabled(false);
            toast.error('Something went wrong');
        }
    }

    return (
        <Box sx={{ flexGrow: 1, }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center">
                        <Grid item>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => history.push('/sites/lists')}
                                startIcon={<ArrowBackIcon />}>Back</Button>
                        </Grid>
                        <Grid item style={{ marginRight: 60 }}>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                    <SiteForm
                        disabled={disabled}
                        onSiteSubmit={handleSubmit} />
                </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
        </Box>
    );
}

export default AddSite;