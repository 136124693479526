import * as React from 'react';
import { Snackbar,  } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const SnackbarComponent = ({ open, onClose }) => {

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                open={open}
                autoHideDuration={4000}
                onClose={onClose}
                message="Updating Domain Please Wait ..."
                action={action}
            />
        </div>
    );
}

export default SnackbarComponent;