export const LOCAL_STORAGE_CURRENT_ORG = "local_storage_current_org";
export const LOCAL_STORAGE_CURRENT_ACCOUNT = "local_storage_current_account";
export const LOCAL_STORAGE_CURRENT_USER = "local_storage_current_user";
export const LOCAL_STORAGE_INVITE_CODE = "local_storage_invite_code";

export const getLocalStorage = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch (error) {
    return null;
  }
};

export const setLocalStorage = (key, value) => {
  try {
    return localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    return null;
  }
};
