import React, { useState, useEffect, useContext } from "react";
import { TextField, Grid, Button } from "@mui/material";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { Firestore } from "../../components/FirebaseAuth/firebase";
import { AuthContext } from "../../components/FirebaseAuth";
import { convertToSlug } from "../../utils";

const SiteForm = ({ disabled, onSiteSubmit }) => {
  const params = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const { authUser } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  async function getSingleSite(id) {
    const siteDocRef = Firestore.collection("sites").doc(id);
    const doc = await siteDocRef.get();
    if (doc.exists) {
      const { name, description, siteType } = doc.data();
      setValue("name", name);
      setValue("description", description);
      setValue("siteType", siteType);
      setIsEditing(true);
    }
  }

  useEffect(() => {
    const { id } = params;
    if (!!id) {
      getSingleSite(id);
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();
    const { name: text } = data;
    const slug = convertToSlug(text);
    const finalObj = {
      ...data,
      slug,
      createdAt: Date.now(),
      adminAccountEmail: authUser.user.email,
    };
    onSiteSubmit(finalObj, params.id);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={!!errors.name}
            id="name"
            focused={isEditing}
            helperText={
              errors.name &&
              errors.name.type === "required" &&
              "Please enter Site Name"
            }
            {...register("name", { required: true, maxLength: 20 })}
            label="Site Name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            focused={isEditing}
            error={!!errors.description}
            id="description"
            helperText={
              errors.description &&
              errors.description.type === "required" &&
              "Please enter Site Description"
            }
            {...register("description", { required: true })}
            label="Site Description"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            focused={isEditing}
            error={!!errors.siteType}
            id="siteType"
            helperText={
              errors.siteType &&
              errors.siteType.type === "required" &&
              "Please enter Site Type"
            }
            {...register("siteType", { required: true })}
            label="Site Type"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            disabled={disabled}
            variant="contained"
            fullWidth
            type="submit"
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default SiteForm;
