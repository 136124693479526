import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid } from '@mui/material';

import { BreadcrumbContext } from '../../../../components/Breadcrumb';
import DataTable from '../../../../components/DataTable'

const Subscriptions = () => {

    const title = 'View Account Subscriptions List';
    const total = -1;
    const rows = [{ accountNo: 44, noOfSites: 12, status: "Active" }, { accountNo: 45, noOfSites: 14, status: "Active" }, { accountNo: 55, noOfSites: 18, status: "InActive" }];
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    // const [rows, setRows] = useState([{ accountNo: 44, noOfSites: 12, status: "Active" }, { accountNo: 45, noOfSites: 14, status: "Active" }, { accountNo: 55, noOfSites: 18, status: "InActive" }]);
    // const [total, setTotal] = useState(-1);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        setBreadcrumb([
            {
                to: "/",
                text: "Home",
                active: false
            },
            {
                to: "/accounts/subscriptions",
                text: "Subscriptions",
                active: false
            },
            {
                to: null,
                text: title,
                active: true
            }
        ]);
    }, [setBreadcrumb]);

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                    <DataTable
                        columns={[
                            { name: "Account No. ", field: "accountNo", style: { width: '50%' } },
                            { name: "No. of Sites in Account", field: "noOfSites", style: { width: '50%' } }]}
                        rows={rows}
                        totalRows={total}
                        pageSize={pageSize}
                        page={page}
                        handlePageChane={(e, p) => {
                            if (p > page) {
                                // getLogs(pageSize, 'next', qs.docs[qs.docs.length-1]);
                            }
                            if (p < page) {
                                // getLogs(pageSize, 'previous', qs.docs[0]);
                            }
                            setPage(p);
                        }}
                        handlePageSizeChange={(e) => {
                            setPageSize(e.target.value);
                            setPage(0);
                        }}
                        isSubscriptions
                    ></DataTable>
                </Grid>
                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    );
}

export default Subscriptions;