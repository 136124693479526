import * as React from 'react';
import { styled } from '@mui/material/styles';
import { TableBody, Table, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: '#6A6A6A',
        fontStyel: 'Roboto',
        fontWeight: 700,
        fontSize: 16
    },
    [`&.${tableCellClasses.body}`]: {
        fontStyel: 'Roboto',
        fontSize: 14,
        fontWeight: 400,
        color: '#6A6A6A'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#F3F6F8',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const DomainsTable = ({ domainList }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Domain</StyledTableCell>
                        <StyledTableCell>Record Type</StyledTableCell>
                        <StyledTableCell>Recommended Value</StyledTableCell>
                        <StyledTableCell>Detected Value</StyledTableCell>
                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell>Status Message</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {domainList.map((domain) => (
                        <StyledTableRow key={domain.id}>
                            <StyledTableCell component="th" scope="row">
                                {domain.id}
                            </StyledTableCell>
                            <StyledTableCell>{domain.type}</StyledTableCell>
                            <StyledTableCell>{domain.primary ? "Yes" : "No"}</StyledTableCell>
                            <StyledTableCell>{domain.deletable ? "Yes" : "No"}</StyledTableCell>
                            <StyledTableCell>{domain.status}</StyledTableCell>
                            <StyledTableCell>{domain.statusMessage}</StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default DomainsTable;