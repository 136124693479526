import { useCallback, useRef, useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  ButtonGroup,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
} from "@mui/material";

const SplitButton = ({ optionsArray, onClick }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = useCallback(
    (index) => {
      onClick(optionsArray[index]);
      setSelectedIndex(index);
      setOpen(false);
    },
    [onClick, optionsArray]
  );

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, []);

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
        sx={{ width: "100%" }}
      >
        <Button
          disabled={selectedIndex === 0}
          style={{
            backgroundColor: "#F3F6F8",
            padding: "4px 12px",
            color: selectedIndex === 0 ? "#314451" : "#0A79A6",
            borderColor: "transparent",
            width: "100%",
          }}
        >
          {optionsArray[selectedIndex].name}
        </Button>
        <Button
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        style={{
          zIndex: 2,
          width: "100%",
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {optionsArray.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      size="sm"
                      style={{
                        color: "#0A79A6",
                      }}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default SplitButton;
