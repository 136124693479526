import { Box, CircularProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useCallback, useMemo, useState } from "react";
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import { PerformanceDetailsModal } from "./PerformanceDetailsModal";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "8px",
  },
  chartContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",
  },
  subContainer: {
    position: "relative",
  },
  percentLabel: {
    position: "absolute",
    inset: "0 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 700,
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
    cursor: "pointer",
  },
  titleLabel: {
    color: "#6A6A6A",
    fontSize: "14px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  infoIcon: {
    fontSize: "14px",
    color: "#9F9F9F",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
}));

export const SitePerformanceChart = ({ webCheckResult }) => {
  const classes = useStyles();

  const [selectedCategory, setSelectedCategory] = useState();

  const chartData = useMemo(() => {
    const categories = [];
    if (webCheckResult?.categories) {
      ["performance", "accessibility", "best-practices", "seo"].forEach(
        (key) => {
          const category = webCheckResult.categories[key];
          categories.push(category);
        }
      );
    }
    return categories;
  }, [webCheckResult?.categories]);

  const getChartColor = useCallback((score) => {
    if (score < 0.5) {
      return "error";
    }
    if (score > 0.8) {
      return "success";
    }
    return "warning";
  }, []);

  const handleClickInfo = useCallback(
    (category) => {
      const relatedAudits = category.auditRefs.map((audit) => audit.id);
      const failedAudits = [];
      const passedAudits = [];
      relatedAudits.forEach((audit) => {
        const auditDetail = webCheckResult.audits[audit];
        if (auditDetail?.score === 1) {
          passedAudits.push(auditDetail);
        }
        if (auditDetail?.score < 1) {
          failedAudits.push(auditDetail);
        }
      });
      setSelectedCategory({
        failedAudits,
        passedAudits,
        title: category.title,
      });
    },
    [webCheckResult?.audits]
  );

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        {chartData.map((category) => (
          <Box className={classes.chartContainer} key={category.title}>
            <Box className={classes.subContainer}>
              <CircularProgress
                variant="determinate"
                value={100}
                size={78}
                sx={{ position: "absolute", color: "#EAEAEA" }}
              />
              <CircularProgress
                variant="determinate"
                value={category.score * 100}
                size={78}
                color={getChartColor(category.score)}
              />
              <Typography className={classes.percentLabel}>{`${(
                category.score * 100
              ).toFixed(0)}%`}</Typography>
            </Box>
            <Box className={classes.titleContainer}>
              <Typography className={classes.titleLabel}>
                {category.title}
              </Typography>
              <HelpOutlinedIcon
                className={classes.infoIcon}
                onClick={() => handleClickInfo(category)}
              />
            </Box>
          </Box>
        ))}
      </Box>
      {!!selectedCategory && (
        <PerformanceDetailsModal
          open={!!selectedCategory}
          handleClose={() => setSelectedCategory()}
          title={selectedCategory.title}
          failedAudits={selectedCategory.failedAudits}
          passedAudits={selectedCategory.passedAudits}
        />
      )}
    </Box>
  );
};
