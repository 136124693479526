import React, { useContext, useEffect, useState } from "react";
import { Stack, Button, Alert, Paper } from "@mui/material";
import { useHistory } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

import MaterialTable from "../../../../components/MaterialTable";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";

const columns = [
  { id: "name", label: "Name", minWidth: 170 },
  { id: "description", label: "Description", minWidth: 170 },
  { id: "siteType", label: "Site Type", minWidth: 170 },
];

const TemplateList = () => {
  const title = "Templates";
  const history = useHistory();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);

  const [templatesList, setTemplatesList] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);

  function fetchSitesList() {
    const sitesCollectionsRef = Firestore.collection("templates").orderBy(
      "name",
      "asc"
    );

    sitesCollectionsRef.onSnapshot((querySnapshot) => {
      const sitesArr = [];
      querySnapshot.forEach((doc) => {
        sitesArr.push({ id: doc.id, ...doc.data() });
      });
      setTemplatesList(sitesArr);
    });
  }

  useEffect(() => {
    fetchSitesList();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: null,
        text: title,
        active: false,
      },
    ]);
  }, [setBreadcrumb, title, currentAccount.id, currentAccount.name]);

  const displayMessage = () => {
    setIsDeleted(true);
    setTimeout(() => setIsDeleted(false), 1000);
  };

  const handleDeletionTemplate = async (id) => {
    try {
      await Firestore.collection("templates").doc(id).delete();
      displayMessage();
    } catch (err) {
      console.log("____ err", err);
    }
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure you want to delete this? This cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => handleDeletionTemplate(id),
        },
        {
          label: "No",
          onClick: () => console.log("___ NO"),
        },
      ],
    });
  };

  const handleEdit = (id) => {
    const url = `/account/${currentAccount.id}/templates/edit/${id}`;
    history.push(url);
  };

  return (
    <Stack spacing={3}>
      {isDeleted && <Alert severity="error">Deleted Successfully</Alert>}
      <div style={{ marginLeft: "auto" }}>
        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            onClick={() =>
              history.push(`/account/${currentAccount.id}/templates/create`)
            }
          >
            Add Template
          </Button>
        </Stack>
      </div>
      <Paper>
        <MaterialTable
          columns={columns}
          rows={templatesList}
          onEdit={handleEdit}
          onDelete={handleDelete}
        />
      </Paper>
    </Stack>
  );
};

export default TemplateList;
