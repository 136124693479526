import React, { useState, useContext, useCallback } from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Toolbar,
  CssBaseline,
  Divider,
  IconButton,
  Box,
  useMediaQuery,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import Logo from "../Logo";
import { BreadcrumbContext, Breadcrumb } from "../Breadcrumb";
import { AuthContext } from "../../components/FirebaseAuth";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../utils/localStorage";
import { useIsMobileView } from "../../hooks/useIsMobileView";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Layout = ({ drawerMenu, toolbarChildren, toolBarMenu, children }) => {
  const theme = useTheme();
  const isMobileView = useIsMobileView();
  const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
  const { isDrawerOpen, setIsDrawerOpen } = useContext(AuthContext);
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(true);
  }, [setIsDrawerOpen]);

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleToggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const [breadcrumb, setBreadcrumb] = useState([]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />

      {matches ? (
        <>
          <AppBar
            position="fixed"
            open={isDrawerOpen}
            style={{
              backgroundColor: currentOrganization?.primaryColor || "#0F4F68",
            }}
          >
            <Toolbar>
              <Box
                style={{
                  paddingRight: "20px",
                  display: isDrawerOpen ? "none" : "inline-flex",
                }}
              >
                <Logo organization={currentOrganization} />
              </Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginRight: "36px",
                  ...(isDrawerOpen && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              {toolbarChildren}
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "0px",
                }}
              >
                {toolBarMenu}
              </div>
            </Toolbar>
          </AppBar>
          <Drawer variant="permanent" open={isDrawerOpen}>
            <DrawerHeader
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: "18px",
              }}
            >
              <Logo isMobile organization={currentOrganization} />
              <Typography
                sx={{
                  color: currentOrganization?.primaryColor,
                  fontSize: "1rem",
                  fontWeight: "700",
                  maxWidth: "140px",
                  marginLeft: "6px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {currentOrganization?.name}
              </Typography>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon
                    style={{
                      color: currentOrganization?.primaryColor || "#59C8DE",
                      fontSize: "36px",
                    }}
                  />
                ) : (
                  <ChevronLeftIcon
                    style={{
                      color: currentOrganization?.primaryColor || "#59C8DE",
                      fontSize: "36px",
                    }}
                  />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            {drawerMenu}
            <Divider />
          </Drawer>
        </>
      ) : (
        <>
          <AppBar
            position="fixed"
            open={false}
            style={{
              backgroundColor: currentOrganization?.primaryColor || "#0F4F68",
            }}
          >
            <Toolbar>
              <div
                style={{
                  paddingRight: "20px",
                  display: false ? "none" : "inline-flex",
                }}
              >
                <Logo organization={currentOrganization} />
              </div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleToggleDrawer}
                edge="start"
                sx={{
                  marginRight: "36px",
                  ...(false && { display: "none" }),
                }}
              >
                <MenuIcon />
              </IconButton>
              {toolbarChildren}
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "0px",
                }}
              >
                {toolBarMenu}
              </div>
            </Toolbar>
          </AppBar>
          <SwipeableDrawer
            anchor="left"
            open={isDrawerOpen}
            onClose={handleDrawerClose}
            onOpen={() => {}}
          >
            {drawerMenu}
          </SwipeableDrawer>
        </>
      )}
      <Box component="main" sx={{ flexGrow: 1, overflow: "hidden" }}>
        <DrawerHeader />
        <Box
          width={1}
          style={{
            position: "fixed",
            zIndex: "1200",
            backgroundColor: "rgba(256, 256, 256, 0.5)",
          }}
        >
          <Box p={2}>
            <Breadcrumb links={breadcrumb} />
          </Box>
        </Box>
        <div style={{ position: "relative" }}>
          <Box sx={{ margin: isMobileView ? "80px 16px" : "80px 24px" }}>
            <BreadcrumbContext.Provider value={{ setBreadcrumb }}>
              {children}
            </BreadcrumbContext.Provider>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default Layout;
