import React from "react";
import { Container } from "@mui/material";
import UserProfileView from "../../../../components/user/UserProfileView";
import UserPageLayout from "../../../../components/user/UserPageLayout";

const UserProfile = () => {
  return (
    <Container maxWidth="md" style={{ marginTop: 130, minHeight: "100vh" }}>
      <UserPageLayout title="Manage Your Profile">
        <UserProfileView />
      </UserPageLayout>
    </Container>
  );
};

export default UserProfile;
