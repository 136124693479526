import React from "react";
import { Container } from "@mui/material";
import UserOrganizationView from "../../../components/user/UserOrganizationView";
import { useParams } from "react-router-dom";
import OrganizationPageLayout from "../../../components/Organizations/OrganizationLayout";

const OrganizationDetails = () => {
  const params = useParams();
  return (
    <Container maxWidth="md" style={{ marginTop: 130, height: "100vh" }}>
      <OrganizationPageLayout title="Manage Organization">
        <UserOrganizationView organizationId={params.organizationId} />
      </OrganizationPageLayout>
    </Container>
  );
};

export default OrganizationDetails;
