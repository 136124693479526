import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Button,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";

import { Firestore } from "../../components/FirebaseAuth/firebase";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
  setLocalStorage,
} from "../../utils/localStorage";

const AccountDetailsTable = ({ accountData }) => {
  const history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleEdit = (accountId) => {
    const url = `/new-account/${accountId}`;
    history.push(url);
  };

  const handleDeleted = async (accountId) => {
    const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
    try {
      const accountRef = Firestore.collection("accounts").doc(accountId);
      const organizationRef = Firestore.collection("organizations").doc(
        currentOrganization.id
      );
      await accountRef.set(
        {
          status: "deleted",
        },
        { merge: true }
      );
      await organizationRef.set(
        {
          accounts: currentOrganization.accounts.filter(
            (account) => account !== accountId
          ),
        },
        { merge: true }
      );
      setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, {
        ...currentOrganization,
        accounts: currentOrganization.accounts.filter(
          (account) => account !== accountId
        ),
      });
      history.push("/");
    } catch (ex) {}
  };

  const handleDelete = (accountId) => {
    confirmAlert({
      title: "Are you sure?",
      message: "Are you sure you want to delete this? This cannot be undone.",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleted(accountId),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: matches ? 650 : 0 }} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              <Box
                style={{
                  color: "#1E1E1E",
                  textTransform: "uppercase",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Account Name
              </Box>
            </TableCell>
            <TableCell align="left">
              <Box
                style={{
                  color: "#5C5C5C",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: 16,
                }}
              >
                {accountData.name}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Box
                style={{
                  color: "#1E1E1E",
                  textTransform: "uppercase",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Account Type
              </Box>
            </TableCell>
            <TableCell align="left">
              <Box
                style={{
                  color: "#5C5C5C",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: 16,
                }}
              >
                {accountData.accountType}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              <Box
                style={{
                  color: "#1E1E1E",
                  textTransform: "uppercase",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: 16,
                }}
              >
                Created
              </Box>
            </TableCell>
            <TableCell align="left">
              <Box
                style={{
                  color: "#5C5C5C",
                  fontFamily: "Roboto",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: 16,
                }}
              >
                {new Date(Date(accountData.creationTime)).toDateString()}
              </Box>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <Button
                variant="contained"
                onClick={() => handleEdit(accountData.id)}
              >
                EDIT
              </Button>
              <Button
                type="cancel"
                variant="contained"
                style={{
                  backgroundColor: "#F3F6F8",
                  color: "#BD1B00",
                  marginLeft: 14,
                }}
                onClick={() => handleDelete(accountData.id)}
              >
                DELETE ACCOUNT
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountDetailsTable;
