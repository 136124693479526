import React, { useEffect, useState, useContext } from "react";
import { Redirect, useParams } from "react-router-dom";
import { FirebaseAuth, Firestore } from "../FirebaseAuth/firebase";
import UserMenu from "../menus/UserMenu";
import AppMenu from "../menus/AppMenu";
import PublicTemplate from "./PublicTemplate";
import Loader from "../Loader";
import { AuthContext } from "../FirebaseAuth";
import AccountMenu from "../menus/AccountMenu";
import Layout from "../Layout";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  setLocalStorage,
} from "../../utils/localStorage";

const AccountTemplate = ({ role, allowInactive, children }) => {
  const { accountId } = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { setUserData, authUser } = useContext(AuthContext);
  const [isActive, setIsActive] = useState(false);

  allowInactive = allowInactive || false;

  useEffect(() => {
    let account = {};
    setLoading(true);
    const accountRef = FirebaseAuth.firestore().doc(`accounts/${accountId}`);
    accountRef
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          if (
            doc.data().subscriptionStatus &&
            doc.data().subscriptionStatus === "active"
          ) {
            setIsActive(true);
          }
          account.id = doc.id;
          account.owner = doc.data().owner;
          account.name = doc.data().name;
          account.accountType = doc.data().accountType;
          account.planId = null;
          if (doc.data().plan) {
            account.planId = doc.data().plan.id;
          }
          account.price = doc.data().price;
          account.currency = doc.data().currency;
          account.paymentCycle = doc.data().paymentCycle;
          account.subscriptionStatus = doc.data().subscriptionStatus;
          account.subscriptionCurrentPeriodEnd =
            doc.data().subscriptionCurrentPeriodEnd;
          account.role =
            doc.data().admins.indexOf(authUser.user.uid) === -1
              ? "user"
              : "admin";
          setLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT, account);
          const userRef = await Firestore.collection("users")
            .doc(authUser.user.uid)
            .get();
          if (
            account.role !== role &&
            role !== "*" &&
            !userRef.data()?.role?.includes("kwallAdmin")
          ) {
            setError("Permission denied.");
          }
          setLoading(false);
        } else {
          setError("Invalid account.");
          setLoading(false);
        }
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [accountId, setUserData, role, authUser]);

  return (
    <>
      {loading ? (
        <PublicTemplate>
          <Loader text="Loading..." />
        </PublicTemplate>
      ) : (
        <>
          {error === null ? (
            isActive || (!isActive && allowInactive) ? (
              <Layout
                drawerMenu={isActive ? <AccountMenu /> : <AppMenu />}
                toolBarMenu={<UserMenu />}
              >
                {children}
              </Layout>
            ) : (
              <Redirect to={"/account/" + accountId + "/sites"}></Redirect>
            )
          ) : (
            <PublicTemplate>{error}</PublicTemplate>
          )}
        </>
      )}
    </>
  );
};

export default AccountTemplate;
