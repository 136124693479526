import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Modal,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "800px",
    height: "600px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "12px",
    borderRadius: "4px",
    boxShadow: 24,
    overflow: "auto",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    marginBottom: "20px",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  itemContainer: {
    display: "flex",
    gap: "2px",
  },
  itemNameLabel: {
    fontSize: "16px",
    fontWeight: "700",
    color: "#6A6A6A",
  },
  itemValueLabel: {
    fontSize: "16px",
    color: "#6A6A6A",
  },
  titleLabel: {
    fontSize: "22px",
    color: "#1E1E1E",
  },
}));

export const PerformanceDetailsModal = ({
  failedAudits,
  passedAudits,
  title,
  open,
  handleClose,
}) => {
  const classes = useStyles();

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className={classes.modalContainer}>
        <Box className={classes.headerContainer}>
          <Typography className={classes.titleLabel}>{title}</Typography>
          <CancelIcon onClick={handleClose} />
        </Box>
        <Box>
          <Accordion className={classes.mainContainer}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Failed Audits
            </AccordionSummary>
            <AccordionDetails>
              {failedAudits.map((failed) => (
                <Box className={classes.itemContainer} key={failed.id}>
                  <Typography className={classes.itemNameLabel}>
                    {failed.title}:
                  </Typography>
                  <Typography className={classes.itemValueLabel}>
                    {failed.displayValue}
                  </Typography>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Passed Audits
            </AccordionSummary>
            <AccordionDetails>
              {passedAudits.map((passed) => (
                <Box className={classes.itemContainer} key={passed.id}>
                  <Typography className={classes.itemNameLabel}>
                    {passed.title}:
                  </Typography>
                  {passed.displayValue ? (
                    <Typography className={classes.itemValueLabel}>
                      {passed.displayValue}
                    </Typography>
                  ) : (
                    <>
                      <CheckBoxIcon color="success" />
                      <Typography className={classes.itemValueLabel}>
                        Pass
                      </Typography>
                    </>
                  )}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </Modal>
  );
};
