import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import {
  Container,
  Paper,
  Box,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  AccordionDetails,
  Typography,
  Avatar,
  Stack,
  IconButton,
  Input as MuiInput,
} from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {
  CloudFunctions,
  FirebaseStorage,
  Firestore,
} from "../../../../components/FirebaseAuth/firebase";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Form, Input } from "../../../../components/Form";

import "./index.css";
import {
  accountTypesArr,
  fontArray,
  termsUrl,
} from "../../../../utils/constants";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
  setLocalStorage,
} from "../../../../utils/localStorage";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: "12px 0",
  },
  imageContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: 130,
    height: 130,
    marginTop: 24,
  },
  uploadInput: {
    display: "none",
  },
  customFont: {
    fontFamily: "inherit",
  },
  Manuale: {
    fontFamily: "Manuale",
  },
  "Playfair Display": {
    fontFamily: "Playfair Display",
  },
  "PT Serif": {
    fontFamily: "PT Serif",
  },
  Roboto: {
    fontFamily: "Roboto",
  },
  "Roboto Condensed": {
    fontFamily: "Roboto Condensed",
  },
  Montserrat: {
    fontFamily: "Montserrat",
  },
  "Open Sans": {
    fontFamily: "Open Sans",
  },
  Jost: {
    fontFamily: "Jost",
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))({
  border: "none",
  "&::before": {
    display: "none",
  },
});

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    flexDirection: "row-reverse",
    padding: 0,
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
      marginLeft: theme.spacing(1),
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  })
);

const NewAccount = () => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
  const mountedRef = useRef(true);
  const history = useHistory();
  const params = useParams();
  const title = !!params.accountId ? "Update Account" : "Create New Account";
  const initialAccNameStates = {
    hasError: false,
    error: null,
    value: null,
  };
  const initialAccType = {
    hasCompleted: null,
    value: null,
  };

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [accountName, setAccountName] = useState(initialAccNameStates);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [useAdvanced, setUseAdvanced] = useState(false);
  const [accountType, setAccountType] = useState(initialAccType);
  const [errorMessage, setErrorMessage] = useState(null);
  const [inSubmit, setInSubmit] = useState(false);
  const [accountData, setAccountData] = useState(null);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, title]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const fetchSingleAccount = useCallback(async (accountId) => {
    try {
      const accountRef = Firestore.collection("accounts").doc(accountId);
      const accountData = (await accountRef.get()).data();
      const { name, accountType, acceptTerms } = accountData;
      setAccountData(accountData);
      setAccountName((prev) => ({
        ...prev,
        value: name,
      }));
      setAccountType({
        ...accountType,
        value: accountType,
      });
      setAcceptTerms(acceptTerms);
      setUseAdvanced(!!accountData.useAdvanced);
    } catch (ex) {
      console.log("____ ex", ex);
    }
  }, []);

  const handleUpload = async (image) => {
    try {
      const downloadUrl = await FirebaseStorage.ref(
        `accounts/${image.name}`
      ).put(image);
      return downloadUrl.ref.getDownloadURL();
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const handleChangeImage = useCallback(async (event) => {
    event.preventDefault();
    if (!!event.target.files[0]) {
      const uploadUrl = await handleUpload(event.target.files[0]);
      setAccountData((prev) => ({ ...prev, imageUrl: uploadUrl }));
    }
  }, []);

  useEffect(() => {
    if (!!params.accountId) {
      fetchSingleAccount(params.accountId);
    }
  }, [fetchSingleAccount, params.accountId]);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      setAccountType({
        ...accountType,
        hasCompleted: false,
        value: value,
      });
    },
    [accountType]
  );

  const handleChangeAdvancedOptions = useCallback(
    ({ target: { name, value } }) => {
      setAccountData((prev) => ({ ...prev, [name]: value }));
    },
    []
  );

  const handleUpdate = useCallback(async () => {
    try {
      const finalObj = {
        ...accountData,
        name: accountName.value,
        accountType: accountType.value,
        acceptTerms,
        useAdvanced,
      };
      const accountRef = Firestore.collection("accounts").doc(params.accountId);
      await accountRef.set({
        ...finalObj,
      });
      history.push("/");
      return;
    } catch (ex) {
      console.log("____ ex", ex);
    }
  }, [
    history,
    accountData,
    accountName,
    accountType,
    acceptTerms,
    params,
    useAdvanced,
  ]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (!!params.accountId) {
        handleUpdate();
        return;
      }
      setInSubmit(true);
      setErrorMessage(null);
      const createAccount = CloudFunctions.httpsCallable(
        "kwallCloud-createAccount"
      );
      createAccount({
        accountData: {
          ...accountData,
          name: accountName.value,
          accountType: accountType.value,
          acceptTerms,
          useAdvanced,
        },
        organizationId: currentOrganization?.id,
      })
        .then((response) => {
          if (!mountedRef.current) return null;
          const accountId = response.data;
          setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, {
            ...currentOrganization,
            accounts: [...(currentOrganization.accounts || []), accountId],
          });
          history.push(`/account/${accountId}/sites`);
        })
        .catch((err) => {
          if (!mountedRef.current) return null;
          setErrorMessage(err.message);
          setInSubmit(false);
        });
    },
    [
      params.accountId,
      accountData,
      accountName.value,
      accountType.value,
      acceptTerms,
      useAdvanced,
      currentOrganization,
      handleUpdate,
      history,
    ]
  );

  return (
    <Container p={isMobile ? 0 : 2}>
      <Paper>
        <Box p={isMobile ? 2 : 3}>
          {errorMessage !== null && (
            <Alert
              severity="error"
              dismissible={true}
              onDismiss={() => setErrorMessage(null)}
            >
              {errorMessage}
            </Alert>
          )}
          <div className="card-body">
            <Form
              handleSubmit={handleSubmit}
              disabled={
                accountName.hasError ||
                accountName.value === null ||
                accountType.hasCompleted ||
                accountType.value === null ||
                inSubmit ||
                !acceptTerms
              }
              inSubmit={inSubmit}
              enableDefaultButtons={true}
            >
              <Input
                label="Account Name"
                type="text"
                value={accountName.value ? accountName.value : ""}
                name="account-name"
                maxLen={100}
                required={true}
                changeHandler={setAccountName}
                fullWidth
                variant="outlined"
              />
              <FormControl fullWidth>
                <InputLabel id="accountType">Account Type</InputLabel>
                <Select
                  labelId="accountType"
                  name="value"
                  type="text"
                  value={accountType.value ?? ""}
                  onChange={handleChange}
                  label="Site Type"
                >
                  {accountTypesArr.map((accType) => (
                    <MenuItem value={accType} key={accType}>
                      {accType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Accordion>
                <AccordionSummary>
                  <Typography
                    sx={{ textDecoration: "underline", color: "#0A79A6" }}
                  >
                    Advanced options
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <TextField
                    label="Primary Color"
                    placeholder=""
                    type="color"
                    value={accountData?.primaryColor || "#000000"}
                    name="primaryColor"
                    onChange={handleChangeAdvancedOptions}
                    fullWidth
                    variant="outlined"
                    className={`${classes.textField} ColorInput`}
                  />
                  <TextField
                    label="Secondary Color"
                    placeholder=""
                    type="color"
                    value={accountData?.secondaryColor || "#000000"}
                    name="secondaryColor"
                    onChange={handleChangeAdvancedOptions}
                    fullWidth
                    variant="outlined"
                    className={`${classes.textField} ColorInput`}
                  />

                  <FormControl fullWidth className={classes.textField}>
                    <InputLabel id="primaryFont">Primary Font</InputLabel>
                    <Select
                      labelId="primaryFont"
                      name="primaryFont"
                      type="text"
                      value={accountData?.primaryFont || fontArray[0]}
                      onChange={handleChangeAdvancedOptions}
                      label="Primary Font"
                    >
                      {fontArray?.map((fontType) => (
                        <MenuItem
                          value={fontType}
                          key={fontType}
                          className={`${classes[fontType]} ${classes.customFont}`}
                        >
                          <div
                            className={`${classes[fontType]} ${classes.customFont}`}
                          >
                            {fontType}
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth className={classes.textField}>
                    <InputLabel id="secondaryFont">Secondary Font</InputLabel>
                    <Select
                      labelId="secondaryFont"
                      name="secondaryFont"
                      type="text"
                      value={accountData?.secondaryFont || fontArray[0]}
                      onChange={handleChangeAdvancedOptions}
                      label="Secondary Font"
                    >
                      {fontArray?.map((fontType) => (
                        <MenuItem
                          value={fontType}
                          key={fontType}
                          className={`${classes[fontType]} ${classes.customFont}`}
                        >
                          <div
                            className={`${classes[fontType]} ${classes.customFont}`}
                          >
                            {fontType}
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl fullWidth className={classes.textField}>
                    <InputLabel id="tertiaryFont">Tertiary Font</InputLabel>
                    <Select
                      labelId="tertiaryFont"
                      name="tertiaryFont"
                      type="text"
                      value={accountData?.tertiaryFont || fontArray[0]}
                      onChange={handleChangeAdvancedOptions}
                      label="Tertiary Font"
                    >
                      {fontArray?.map((fontType) => (
                        <MenuItem
                          value={fontType}
                          key={fontType}
                          className={`${classes[fontType]} ${classes.customFont}`}
                        >
                          <div
                            className={`${classes[fontType]} ${classes.customFont}`}
                          >
                            {fontType}
                          </div>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={useAdvanced}
                        onClick={() =>
                          setUseAdvanced((prevState) => !prevState)
                        }
                        size="small"
                      />
                    }
                    label={
                      <Box>Use this advanced information on site deploy</Box>
                    }
                  />
                  <Box className={classes.imageContainer}>
                    <Avatar
                      alt={accountData?.imageUrl || ""}
                      src={accountData?.imageUrl || ""}
                      className={classes.image}
                    />
                    <Stack>
                      <label htmlFor="contained-button-file">
                        <MuiInput
                          accept="image/*"
                          id="contained-button-file"
                          name="image"
                          type="file"
                          onChange={(e) => handleChangeImage(e)}
                          className={classes.uploadInput}
                        />
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span"
                        >
                          <CloudUploadIcon />
                          <Box>
                            <Typography ml={1}>Update Image</Typography>
                          </Box>
                        </IconButton>
                      </label>
                    </Stack>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={acceptTerms}
                    onClick={() => setAcceptTerms((prevState) => !prevState)}
                    size="small"
                  />
                }
                label={
                  <Button
                    href={termsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "underline",
                      color: "#0A79A6",
                      textTransform: "inherit",
                      fontSize: "16px",
                    }}
                  >
                    <Box>I Agree to Terms &amp; Conditions</Box>
                  </Button>
                }
              />
            </Form>
          </div>
        </Box>
      </Paper>
    </Container>
  );
};

export default NewAccount;
