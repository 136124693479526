import React, { useCallback, useEffect, useState } from "react";
import { FirebaseAuth, Firestore } from "./firebase";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
  setLocalStorage,
} from "../../utils/localStorage";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState({
    user: null,
    checked: false,
  });

  const [userData, setUserData] = useState({});
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMyTemplate, setIsMyTemplate] = useState({
    isMenuOpen: false,
    status: false,
  });

  const initializeUserData = useCallback(async (user) => {
    const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
    const userRef = await Firestore.collection("users").doc(user.uid).get();
    const organizationRef = Firestore.collection("organizations").where(
      "access",
      "array-contains",
      FirebaseAuth.auth().currentUser.uid
    );
    const organizationSnapshot = await organizationRef.get();
    let organizations = [];
    organizationSnapshot.forEach((org) => {
      organizations.push({
        ...org.data(),
        id: org.id,
      });
    });
    setLocalStorage(LOCAL_STORAGE_CURRENT_USER, userRef.data());
    if (organizations.length > 0 && !currentOrganization) {
      setLocalStorage(LOCAL_STORAGE_CURRENT_ORG, organizations?.[0]);
    }
  }, []);

  useEffect(() => {
    FirebaseAuth.auth().onAuthStateChanged((user) => {
      setAuthUser({
        user: user,
        checked: true,
      });
      if (user) {
        initializeUserData(user);
      }
    });
  }, [initializeUserData]);

  return (
    <AuthContext.Provider
      value={{
        authUser,
        isMyTemplate,
        userData,
        isDrawerOpen,
        setUserData,
        setIsMyTemplate,
        setIsDrawerOpen,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
