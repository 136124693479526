import React, { useEffect, useState } from "react";

import { Grid, CardMedia, Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { Link } from "react-router-dom";

import { defaultTemplateUrl } from "../../utils/index";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../utils/localStorage";
import { useMemo } from "react";

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: "#6A6A6A",
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: 14,
  },
  badge: {
    fontSize: 10,
    backgroundColor: "#f3f6f8",
    borderRadius: 4,
    padding: "4px 8px",
    color: "#6A6A6A",
    width: "fit-content",
    marginBottom: 8,
  },
  linkContainer: {
    display: "flex",
    flexDirection: "column",
  },
  linkText: {
    fontSize: "12px",
    color: "#0a79a6",
    textDecoration: "underline",
    cursor: "pointer",
  },
}));

const SiteCardMobile = ({
  isTemplatesSite,
  isAdminTemplates,
  site,
  onManage,
  onEdit,
  onDelete,
}) => {
  const classes = useStyles();
  const {
    id,
    siteName,
    siteType,
    image,
    description,
    url,
    status,
    slug,
    webCheckResult,
  } = site;

  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const [siteImage, setSiteImage] = useState("");

  const domainList = useMemo(() => {
    if (siteType === "Manual") {
      return [url];
    }
    if (status === "pending") return [];
    if (status === "pending-test" || status === "active") {
      return [`http://dev-${slug}.pantheonsite.io`];
    }
    if (status === "pending-production" || status === "test") {
      return [
        `http://dev-${slug}.pantheonsite.io`,
        `http://test-${slug}.pantheonsite.io`,
      ];
    }
    if (status === "production") {
      return [
        `http://dev-${slug}.pantheonsite.io`,
        `http://test-${slug}.pantheonsite.io`,
        `http://live-${slug}.pantheonsite.io`,
      ];
    }
  }, [siteType, slug, status, url]);

  useEffect(() => {
    if (image) {
      setSiteImage(image);
    } else {
      const thumbnails =
        webCheckResult?.audits?.["screenshot-thumbnails"]?.details?.items;
      if (thumbnails?.length) {
        setSiteImage(thumbnails[thumbnails.length - 1]?.data);
      } else {
        setSiteImage(defaultTemplateUrl);
      }
    }
  }, [image, webCheckResult?.audits]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <Box m={3}>
              <CardMedia
                component="img"
                alt="Site Image Here"
                height="140"
                image={siteImage}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="flex-start"
              style={{ marginLeft: "2rem" }}
            >
              <Grid item>
                {site.siteType === "Manual" && (
                  <Box className={classes.badge}>EXTERNAL SITE</Box>
                )}
                <Link
                  to={`/account/${currentAccount.id}/site-details/${id}`}
                  style={{ textDecorationColor: "#0A79A6" }}
                >
                  <Box
                    style={{
                      color: "#0A79A6",
                      textDecorationColor: "#0A79A6",
                      fontSize: 16,
                    }}
                  >
                    {siteName}
                  </Box>
                </Link>
                <Box m={1} className={classes.textPrimary}>
                  {description}
                </Box>
                {!!siteType && (
                  <Box
                    m={1}
                    className={classes.textPrimary}
                  >{`Platform: ${siteType}`}</Box>
                )}
                {domainList.length > 0 && (
                  <Box m={1} className={classes.linkContainer}>
                    {domainList.map((domain) => (
                      <Typography
                        className={classes.linkText}
                        onClick={() => window.open(domain, "_blank")}
                      >
                        {domain}
                      </Typography>
                    ))}
                  </Box>
                )}
                <Box
                  m={1}
                  className={classes.textPrimary}
                  style={{ color: "#0A79A6" }}
                >
                  <Link
                    to={site.domain ? site.domain : null}
                    style={{ textDecorationColor: "#0A79A6" }}
                  >
                    <Box style={{ color: "#0A79A6" }}>
                      {site.domain ? site.domain : null}
                    </Box>
                  </Link>
                </Box>

                {!isTemplatesSite && (
                  <Box m={1} mb={3}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => onManage(site.id)}
                    >
                      {" "}
                      Manage{" "}
                    </Button>
                  </Box>
                )}
              </Grid>
            </Grid>
            <Grid xs={12}>
              {isAdminTemplates && (
                <Grid item xs={12}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    style={{ marginBottom: 20, marginLeft: 30 }}
                  >
                    <Grid item>
                      <Box mr={3} mt={4}>
                        <Button
                          type="cancel"
                          variant="contained"
                          style={{
                            backgroundColor: "#F3F6F8",
                            color: "#BD1B00",
                          }}
                          onClick={() => onDelete(site)}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box mr={3} mt={4}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => onEdit(site.id)}
                        >
                          {" "}
                          Edit{" "}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SiteCardMobile;
