import { memo, useContext, useEffect } from "react";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Box } from "@mui/material";
import { SiteMarketPlace } from "../Sites/siteComponents/SiteMarketPlace";

export const Marketplace = memo(() => {
  const title = "Marketplace";
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb]);

  return (
    <Box>
      <SiteMarketPlace />
    </Box>
  );
});
