import React, { useState, useContext, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Alert,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Grid,
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";

import ThemeCard from "../../../../components/ThemeCard";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { AuthContext } from "../../../../components/FirebaseAuth";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import { convertToSlug } from "../../../../utils";
import Loader from "../../../../components/Loader";
import {
  siteFormErrors,
  siteTypesArr,
  templateTypesArr,
} from "../../../../utils/constants";
import { makeStyles } from "@mui/styles";
import { ImageUpload } from "../../../../components/ImageUpload";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  LOCAL_STORAGE_CURRENT_ORG,
  getLocalStorage,
} from "../../../../utils/localStorage";
import { useIsMobileView } from "../../../../hooks/useIsMobileView";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 16,
    fontWeight: 400,
    color: "#000000 ",
  },
  textField: {
    margin: "12px 0",
  },
}));

const initialSiteState = {
  siteName: "",
  slug: "",
  description: "",
  siteType: "",
  url: "",
};

const SiteCreate = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const isMobileView = useIsMobileView();

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const { authUser } = useContext(AuthContext);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);

  const [templateList, setTemplateList] = useState([]);
  const [siteData, setSiteData] = useState(initialSiteState);
  const [templateType, setTemplateType] = useState("");
  const [selectedSiteTypes, setSelectedSiteTypes] = useState("");
  const [isTemplateEmpty, setIsTemplateEmpty] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [errors, setErrors] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isExternal, setIsExternal] = useState(false);
  const [referenceOrgId, setReferenceOrgId] = useState();
  const [organizationSlug, setOrganizationSlug] = useState("");
  const [existSlugs, setExistSlugs] = useState([]);
  const [slugError, setSlugError] = useState("");

  const checkIsTemplateAvailable = useCallback(async () => {
    const snapshot = await Firestore.collection("templates")
      .where("accountType", "array-contains", currentAccount.accountType)
      .get();
    setIsTemplateEmpty(snapshot.empty);
  }, [currentAccount.accountType]);

  const fetchTemplatesList = useCallback(
    async (currentSelectedTemplate, currentSiteType) => {
      const templateRef = Firestore.collection("templates");
      const templateSnapshot = await templateRef
        .where("accountType", "array-contains", currentAccount.accountType)
        .get();
      let templateArr = [];

      const organizationRef = Firestore.collection("organizations");
      const organizationSnapshot = await organizationRef
        .where("accounts", "array-contains", currentAccount.id)
        .get();
      let organizationId = "";
      organizationSnapshot.forEach((doc) => {
        if (doc.data().name === currentOrganization.name) {
          organizationId = doc.id;
          setReferenceOrgId(doc.id);
        }
      });

      const defaultTemplateArray = [];

      templateSnapshot.forEach((doc) => {
        if (!doc.data().customerID || !doc.data().organizationID) {
          defaultTemplateArray.push({ id: doc.id, ...doc.data() });
        } else if (
          ((doc.data().customerID?.length === 0 &&
            doc.data().organizationID?.length === 0) ||
            doc.data().customerID?.includes(currentAccount.id) ||
            doc.data().organizationID?.includes(organizationId)) &&
          !doc.data().excludeCustomerID?.includes(currentAccount.id) &&
          !doc.data().excludeOrganizationID?.includes(organizationId)
        ) {
          defaultTemplateArray.push({ id: doc.id, ...doc.data() });
        }
      });
      const sitesTemplatesRef = Firestore.collection(
        `accounts/${currentAccount.id}/sites`
      );
      const siteTemplateSnapshot = await sitesTemplatesRef
        .where("isMyTemplate", "==", true)
        .get();

      if (currentSelectedTemplate === "Default Starter Kit") {
        templateArr = [...defaultTemplateArray];
      } else if (currentSelectedTemplate === "My Starter Kits") {
        siteTemplateSnapshot.forEach((doc) => {
          templateArr.push({
            id: doc.id,
            ...doc.data(),
            name: doc.data().siteName,
          });
        });
      } else {
        templateArr = [...defaultTemplateArray];

        siteTemplateSnapshot.forEach((doc) => {
          templateArr.push({
            id: doc.id,
            ...doc.data(),
            name: doc.data().siteName,
          });
        });
      }

      if (currentSiteType !== "All") {
        templateArr = templateArr.filter(
          (temp) => temp.siteType === currentSiteType
        );
      }
      setTemplateList(templateArr);
    },
    [currentAccount.id, currentAccount.accountType, currentOrganization.name]
  );

  const handleChange = useCallback(
    ({ target: { name, value } }) => {
      if (name === "siteName") {
        const result = convertToSlug(value);
        setSiteData({
          ...siteData,
          slug: result,
          [name]: value,
        });
      } else if (name === "templateType") {
        setTemplateType(value);
        fetchTemplatesList(value, selectedSiteTypes);
      } else if (name === "siteType") {
        setSelectedSiteTypes(value);
        setSiteData({
          ...siteData,
          [name]: value,
        });
        fetchTemplatesList(templateType, value);
      } else {
        setSiteData({
          ...siteData,
          [name]: value,
        });
      }
    },
    [siteData, fetchTemplatesList, templateType, selectedSiteTypes]
  );

  const handleValidateSlug = useCallback(
    (event) => {
      if (existSlugs.includes(`${event.target.value}${organizationSlug}`)) {
        setSlugError(
          "This slug already exists, please modify and make unique. (add a -1 or -2 to the end 🙂 )"
        );
      } else {
        setSlugError("");
      }
    },
    [existSlugs, organizationSlug]
  );

  const getSlugData = useCallback(async () => {
    if (currentOrganization?.organizationCode) {
      setOrganizationSlug(
        `-${convertToSlug(currentOrganization.organizationCode)}`
      );
    } else {
      const {
        user: { uid: authUserID },
      } = authUser;
      const organizationSnapshots = await Firestore.collection("organizations")
        .where("userId", "==", authUserID)
        .get();
      if (!organizationSnapshots.empty) {
        let slug = "";
        organizationSnapshots.forEach((org) => {
          slug = org.data().organizationCode;
        });
        setOrganizationSlug(`-${convertToSlug(slug)}`);
      }
    }

    const deploySites = await Firestore.collection("deploySites")
      .where("slug", "!=", "")
      .get();
    if (!deploySites.empty) {
      let slugs = [];
      deploySites.forEach((site) => {
        slugs.push(site.data().slug);
      });

      setExistSlugs(slugs);
    }
  }, [authUser, currentOrganization?.organizationCode]);

  const handleValidation = useCallback(() => {
    for (let data in siteData) {
      if (!siteData[data]) {
        if (
          isExternal &&
          (data === "siteName" || data === "url" || data === "description")
        ) {
          setErrors(siteFormErrors[data].message);
          return true;
        }
        if (
          !isExternal &&
          (data === "siteName" ||
            data === "slug" ||
            data === "description" ||
            data === "siteType")
        ) {
          setErrors(siteFormErrors[data].message);
          return true;
        }
      }
    }
    return false;
  }, [siteData, isExternal]);

  const insertSiteInfoToOrganization = useCallback(
    async (siteId, userId) => {
      try {
        if (currentOrganization) {
          let templatesArr =
            !currentOrganization.templates ||
            !!currentOrganization.templates.length === 0
              ? []
              : [...currentOrganization.templates];
          const templateName = selectedTemplate?.name;
          if (templateName) {
            let tempObj = { siteId: siteId, templateName };
            templatesArr.push(tempObj);
            const finalObj = {
              ...currentOrganization,
              templates: templatesArr,
            };
            const refOrganization = Firestore.collection("organizations").doc(
              currentOrganization.id || referenceOrgId
            );
            await refOrganization.set({
              ...finalObj,
            });
          }
          return;
        }
      } catch (ex) {
        console.log("___ ex", ex);
      }
    },
    [selectedTemplate, currentOrganization, referenceOrgId]
  );

  const insertSiteInfoToAccount = useCallback(
    async (sitesCollectionRef, authUserEmail) => {
      let finalObj = {
        ...siteData,
        image: siteData.image || selectedTemplate?.image || "",
        createdAt: Date.now(),
        adminAccountEmail: authUserEmail,
        siteType: "Manual",
        isMyTemplate: false,
      };

      if (!isExternal) {
        finalObj = {
          ...finalObj,
          slug: `${siteData.slug}${organizationSlug}`,
          templateId: selectedTemplate.isMyTemplate
            ? selectedTemplate.templateId
            : selectedTemplate.id,
          templateType: selectedTemplate.isMyTemplate
            ? "site template"
            : "global",
          siteTemplateId: selectedTemplate.id,
          siteCloneId: selectedTemplate.siteId || "",
          siteType: selectedTemplate.siteType,
          status: "pending",
        };
      }

      await sitesCollectionRef.set({ ...finalObj });
    },
    [siteData, isExternal, organizationSlug, selectedTemplate]
  );

  const updateTemplateSiteList = useCallback(async () => {
    const templateRef = await Firestore.collection("templates")
      .doc(selectedTemplate.id)
      .get();
    const templateData = templateRef.data();
    let accountsArr = [];
    if (templateData?.isSelected && templateData.isSelected?.length > 0) {
      accountsArr = [...templateData.isSelected, currentAccount.id];
    }
    await Firestore.collection("templates")
      .doc(selectedTemplate.id)
      .set({ ...templateData, isSelected: accountsArr });
  }, [selectedTemplate, currentAccount.id]);

  const handleSubmit = useCallback(async () => {
    if (handleValidation()) return;
    try {
      setIsSubmitting(true);
      const {
        user: { email: authUserEmail, uid: authUserID },
      } = authUser;
      const sitesCollectionRef = Firestore.collection(
        `/accounts/${currentAccount.id}/sites`
      ).doc();
      await insertSiteInfoToOrganization(sitesCollectionRef.id, authUserID);
      await insertSiteInfoToAccount(sitesCollectionRef, authUserEmail);
      if (isExternal) {
        history.goBack();
      } else {
        await updateTemplateSiteList();
        history.push(
          `/account/${currentAccount.id}/billing/plan/${sitesCollectionRef.id}`
        );
      }
    } catch (ex) {
      console.log("___ ex", ex);
      setIsSubmitting(false);
    }
  }, [
    handleValidation,
    authUser,
    currentAccount.id,
    insertSiteInfoToOrganization,
    insertSiteInfoToAccount,
    isExternal,
    history,
    updateTemplateSiteList,
  ]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/sites`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/sites`,
        text: "Sites",
        active: false,
      },
      {
        to: null,
        text: "Create Site",
        active: true,
      },
    ]);
  }, [setBreadcrumb, currentAccount.id, currentAccount.name]);

  useEffect(() => {
    checkIsTemplateAvailable();
    getSlugData();
  }, [currentAccount.accountType, checkIsTemplateAvailable, getSlugData]);

  useEffect(() => {
    if (location?.state?.isExternal) {
      setIsExternal(location.state.isExternal);
    }
  }, [location]);

  return (
    <>
      {isTemplateEmpty && (
        <Box mb={2}>
          <Alert severity="info">
            If you want to start a site to be a starter kit for your - My
            starter kit section, choose template / starter kit as your payment
            option on checkout.
          </Alert>
        </Box>
      )}
      <Paper sx={{ padding: isMobileView ? "12px" : "24px" }}>
        <Box className={classes.title}>Site Details</Box>
        <Grid container>
          <ImageUpload
            data={siteData}
            setData={setSiteData}
            imageName="image"
            storage="sites"
          />
          <Grid item xs={12} md={9}>
            {errors && (
              <Alert
                severity="error"
                dismissible={true}
                onDismiss={() => setErrors(null)}
              >
                {errors}
              </Alert>
            )}
            <TextField
              label="Site Name*"
              name="siteName"
              fullWidth
              value={siteData.siteName}
              onChange={handleChange}
              className={classes.textField}
            />
            {isExternal ? (
              <TextField
                label="Site URL*"
                name="url"
                fullWidth
                value={siteData.url}
                onChange={handleChange}
                className={classes.textField}
              />
            ) : (
              <TextField
                label="Site Slug*"
                name="slug"
                fullWidth
                value={siteData.slug}
                onChange={handleChange}
                className={classes.textField}
                onBlur={handleValidateSlug}
                error={!!slugError}
                helperText={slugError}
              />
            )}

            <TextField
              label="Site Description"
              name="description"
              fullWidth
              multiline
              minRows={3}
              value={siteData.description}
              onChange={handleChange}
              className={classes.textField}
            />
            {!isExternal && (
              <Box>
                <Typography component="legend" mb={2}>
                  Choose a Starter Kit*
                </Typography>
                <Grid container spacing={2} mb={2}>
                  <Grid item xs={12} md={4} lg={3}>
                    <FormControl fullWidth>
                      <InputLabel component="legend">Site Types</InputLabel>
                      <Select
                        labelId="siteType"
                        name="siteType"
                        fullWidth
                        value={selectedSiteTypes}
                        onChange={handleChange}
                        input={<OutlinedInput label="Site Type" />}
                        renderValue={(selected) => selected}
                      >
                        {siteTypesArr.map((temp) => (
                          <MenuItem key={temp.id} value={temp}>
                            <Checkbox
                              checked={selectedSiteTypes.indexOf(temp) > -1}
                            />
                            <ListItemText primary={temp} />
                          </MenuItem>
                        ))}
                      </Select>
                      {selectedSiteTypes && templateList.length === 0 ? (
                        <FormHelperText style={{ color: "#0A79A6" }}>
                          No starter kit found.
                        </FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={4} lg={3}>
                    {selectedSiteTypes && (
                      <FormControl fullWidth>
                        <InputLabel component="legend">
                          <Box style={{ color: "#000000" }}>
                            {" "}
                            Template Type{" "}
                          </Box>
                        </InputLabel>
                        <Select
                          labelId="templateType"
                          name="templateType"
                          fullWidth
                          value={templateType}
                          onChange={handleChange}
                          input={<OutlinedInput label="Template Type" />}
                          renderValue={(selected) => selected}
                        >
                          {templateTypesArr.map((temp) => (
                            <MenuItem key={temp} value={temp}>
                              <Checkbox checked={templateType === temp} />
                              <ListItemText primary={temp} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  {templateList.map((template, key) => (
                    <Grid item xs={12} md={6} key={key}>
                      <ThemeCard
                        finalSelectedTemplate={selectedTemplate}
                        onSelectTemplate={setSelectedTemplate}
                        selectedTemplate={templateType}
                        theme={template}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            )}
            <Box mt={3}>
              <Button
                variant="contained"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting && <Loader />}
                Create
              </Button>

              <Button
                type="cancel"
                variant="contained"
                onClick={() => history.goBack()}
                style={{
                  backgroundColor: "#F3F6F8",
                  color: "#BD1B00",
                  marginLeft: 12,
                }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default SiteCreate;
