import React, { useState, useContext, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  IconButton,
  Typography,
  Box,
  Avatar,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Paper,
  Grid,
  Button,
  FormHelperText,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import Joi from "joi-browser";
import { useHistory } from "react-router-dom";

import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import Loader from "../../../../components/Loader";
import {
  Firestore,
  FirebaseStorage,
} from "../../../../components/FirebaseAuth/firebase";
import { defaultTemplateUrl } from "../../../../utils/index";
import { templateCreateSchema } from "../../../../utils/schema";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { accountTypesArr } from "../../../../utils/constants";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName === name
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const Input = styled("input")({
  display: "none",
  width: "100%",
});

const TemplateCreate = () => {
  const listName = "templates";
  const title = "Create Template";
  const siteTypesArr = ["Drupal", "Wordpress"];

  const initialImageState = {
    file: null,
    url: null,
  };

  const initialStates = {
    name: "",
    description: "",
    templateId: "",
    accountType: "",
    siteType: "",
  };

  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const { setBreadcrumb } = useContext(BreadcrumbContext);

  const theme = useTheme();
  const history = useHistory();
  const [data, setData] = useState(initialStates);
  const [image, setImage] = useState(initialImageState);
  const [isNoted, setIsNoted] = useState(false);
  const [siteId, setSiteId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({});

  const validate = (siteObj) => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(siteObj, templateCreateSchema, options);

    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  const titleCase = (str) => {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/templates`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/${listName}`,
        text: titleCase(listName),
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, title, listName, currentAccount.id, currentAccount.name]);

  const handleChange = ({ target: { name, value } }) => {
    setData({
      ...data,
      [name]: value,
    });
  };

  const handleChangeImage = ({ target: { files } }) => {
    if (!!files[0]) {
      setImage({
        ...image,
        file: files[0],
        url: URL.createObjectURL(files[0]).toString(),
      });
    }
  };

  const handleUpload = async (image, storagePath) => {
    try {
      const downloadUrl = await FirebaseStorage.ref(
        `${storagePath}/${image.name}`
      ).put(image);
      console.log("Uploaded", downloadUrl.ref.getDownloadURL());
      return downloadUrl.ref.getDownloadURL();
    } catch (ex) {
      console.log("ex", ex);
    }
  };

  const handleValidation = () => {
    if (isSubmitted) {
      let finalObj = { ...data };
      const errors = validate(finalObj);
      setErrors(errors === null ? {} : errors);
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsSubmitted(true);
      setIsSubmitting(true);
      let finalObj = { ...data };
      const errors = validate(finalObj);
      if (errors !== null) {
        setErrors(errors);
        setIsSubmitting(false);
        return;
      }
      let uploadedUrl = defaultTemplateUrl;
      if (!!image.file) {
        uploadedUrl = await handleUpload(image.file);
      }
      finalObj = {
        ...data,
        image: uploadedUrl,
        isNoted,
        siteId,
        siteCloneId: siteId,
        templateType: "My Template",
        createdAt: Date.now(),
      };
      let tempCollectionRef = Firestore.collection("templates").doc();
      await tempCollectionRef.set({
        ...finalObj,
      });

      history.push(`/account/${currentAccount.id}/templates`);
      return true;
    } catch (ex) {
      setIsSubmitting(false);
      return false;
    }
  };

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Template Name"
              name="name"
              value={data.name}
              onChange={handleChange}
              fullWidth
              onBlur={handleValidation}
              error={errors.name ? true : false}
              helperText={errors.name ? errors.name : null}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Template Description"
              name="description"
              value={data.description}
              onChange={handleChange}
              fullWidth
              onBlur={handleValidation}
              error={errors.description ? true : false}
              helperText={errors.description ? errors.description : null}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Template ID"
              name="templateId"
              value={data.templateId}
              onChange={handleChange}
              fullWidth
              onBlur={handleValidation}
              error={errors.templateId ? true : false}
              helperText={errors.templateId ? errors.templateId : null}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <FormControl fullWidth>
                <InputLabel id="accountType">Account Type</InputLabel>
                <Select
                  labelId="accountType"
                  id="accountType"
                  name="accountType"
                  fullWidth
                  value={data.accountType}
                  onChange={handleChange}
                  onBlur={handleValidation}
                  input={<OutlinedInput label="Account Type" />}
                  MenuProps={MenuProps}
                >
                  {accountTypesArr.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, data.accountType, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {errors.accountType && (
                  <FormHelperText style={{ color: "#d32f2f" }}>
                    {errors.accountType}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={siteId}
              onChange={(e) => setSiteId(e.target.value)}
              label="Site ID"
              name="siteId"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="siteType">Site Type</InputLabel>
              <Select
                labelId="siteType"
                name="siteType"
                value={data.siteType}
                onChange={handleChange}
                onBlur={handleValidation}
                type="text"
                label="Site Type"
              >
                {siteTypesArr.map((siteType) => (
                  <MenuItem value={siteType} key={siteType}>
                    {siteType}
                  </MenuItem>
                ))}
              </Select>
              {errors.siteType && (
                <FormHelperText style={{ color: "#d32f2f" }}>
                  {errors.siteType}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isNoted}
                    onClick={() => setIsNoted((prevNoted) => !prevNoted)}
                    name="isNoted"
                  />
                }
                label="Choose Payment of Template"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Avatar alt="Remy Sharp" src={image.url} />
              <Stack direction="row" alignItems="center">
                <label htmlFor="contained-button-file">
                  <Input
                    accept="image/*"
                    id="contained-button-file"
                    name="image"
                    type="file"
                    onChange={handleChangeImage}
                  />
                  <IconButton
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                  >
                    <CloudUploadIcon />{" "}
                    <Box>
                      <Typography ml={1}>Upload Image</Typography>
                    </Box>
                  </IconButton>
                </label>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              {isSubmitting && <Loader />} Create
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default TemplateCreate;
