import { Button, Card, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    minHeight: 180,
    position: "relative",
    border: "1px solid #eee",
    borderRadius: "4px",
    padding: "12px",
  },
  image: {
    height: 120,
    width: 120,
    objectFit: "contain",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    padding: "0 8px",
  },
  title: {
    fontSize: "1.2rem",
    color: "black",
  },
  provider: {
    fontSize: "0.8rem",
    color: "#6a6a6a",
    fontWeight: 600,
  },
  description: {
    fontSize: "0.7rem",
    overflow: "hidden",
  },
  button: {
    height: 36,
    position: "relative",
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    gap: "12px",
    marginTop: "12px",
  },
  priceLabel: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#407543",
  },
  lowestLabel: {
    fontSize: "12px",
  },
  estimationLabel: {
    backgroundColor: "#dcdbdc",
    fontSize: "14px",
    borderRadius: "4px",
    width: "fit-content",
    padding: "0 8px",
  },
}));

export const ServiceCard = ({ service }) => {
  const classes = useStyles();
  const history = useHistory();
  const params = useParams();

  const handleClickRequest = useCallback(() => {
    if (params.accountId) {
      history.push(`/account/${params.accountId}/service/${service.id}`, {
        siteId: params.siteId,
      });
    } else {
      history.push(`/marketplace/request/${service.id}`);
    }
  }, [service, history, params]);

  return (
    <Card className={classes.container}>
      <Box
        component="img"
        alt="Service Image"
        src={service.imageUrl}
        className={classes.image}
      />
      <Box className={classes.content}>
        <Typography className={classes.estimationLabel}>
          {service.estimatedTime}
        </Typography>
        <Typography className={classes.title}>{service.name}</Typography>
        <Typography className={classes.provider}>
          {`Service by ${service.provider}`}
        </Typography>
        <Typography className={classes.description}>
          {service.description}
        </Typography>
        {service.link && (
          <Link
            href={service.link}
            target="_blank"
            className={classes.description}
          >
            Learn More
          </Link>
        )}
        <Box className={classes.rowContainer}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={handleClickRequest}
          >
            Request
          </Button>
          {!service.price ? (
            <Typography className={classes.priceLabel}>
              {service.price}
            </Typography>
          ) : (
            service.lowestPrice && (
              <Box className={classes.columnContainer}>
                <Typography className={classes.lowestLabel}>
                  Starting at
                </Typography>
                <Typography className={classes.priceLabel}>
                  ${service.lowestPrice}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Box>
    </Card>
  );
};
