import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Breadcrumbs, Typography, Link } from "@mui/material";

export const BreadcrumbContext = React.createContext();

export const Breadcrumb = ({ links }) => {
  return (
    <Breadcrumbs>
      {links !== null &&
        links.map((link, key) =>
          link.to ? (
            <Link
              key={key}
              to={link.to}
              component={RouterLink}
              style={{ fontSize: 14 }}
            >
              {link.text}
            </Link>
          ) : (
            <Typography key={key} color="textPrimary" style={{ fontSize: 14 }}>
              {link.text}
            </Typography>
          )
        )}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
