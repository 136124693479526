import React, { useCallback } from "react";
import {
  Grid,
  Card,
  CardHeader,
  Box,
  CardActions,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useHistory } from "react-router-dom";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  setLocalStorage,
} from "../../utils/localStorage";

const useStyles = makeStyles((theme) => ({
  buttonStyle: {
    backgroundColor: "#F3F6F8",
    fontSize: 13,
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  viewSite: {
    color: "#0A79A6",
  },
  titleStyle: {
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: 22,
  },
  subStyle: {
    fontWeight: 400,
    fontFamily: "Roboto",
    fontSize: 14,
  },
}));

const AccountCard = ({ account, i }) => {
  const { id, name, accountType } = account;
  const history = useHistory();
  const classes = useStyles();

  const handleShowSites = useCallback(() => {
    setLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT, account);
    history.push(`/account/${account.id}/sites`);
  }, [account, history]);

  const handleShowOverview = useCallback(() => {
    setLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT, account);
    history.push(`/account-overview/${account.id}`);
  }, [account, history]);

  function getSpecificData(text) {
    return `${String(text.slice(0, 22))} ${text.length > 22 ? "..." : ""}`;
  }

  return (
    <Grid container item xs={12} md={4} key={i}>
      <Card key={account.id} style={{ width: "100%" }}>
        <CardHeader
          title={
            <Box className={classes.titleStyle}>{getSpecificData(name)}</Box>
          }
          subheader={
            <Box className={classes.subStyle}>
              {getSpecificData(accountType)}
            </Box>
          }
        />
        <CardActions>
          {account.subscriptionStatus ? (
            <Grid container>
              <Grid item>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleShowSites}
                >
                  View
                </Button>
              </Grid>
              <Grid item style={{ marginLeft: 10 }}>
                <Button
                  size="small"
                  className={`${classes.buttonStyle}`}
                  onClick={handleShowOverview}
                >
                  Edit
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Button
              size="small"
              color="warning"
              onClick={() => history.push(`/account/${id}/sites`)}
            >
              Activate this collection
            </Button>
          )}
        </CardActions>
      </Card>
    </Grid>
  );
};

export default AccountCard;
