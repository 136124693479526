import React, { useContext, useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Typography,
  Box,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SyncIcon from "@mui/icons-material/Sync";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import WebIcon from "@mui/icons-material/Web";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PaidIcon from "@mui/icons-material/Paid";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { AuthContext } from "../../FirebaseAuth";
import { Firestore } from "../../../components/FirebaseAuth/firebase";
import { isKWALLAdmin } from "../../../utils/index";

import "./index.css";

const AppMenu = () => {
  const initialUserState = {
    isLoading: true,
    data: null,
  };

  const { pathname } = useLocation();
  const { authUser, isDrawerOpen } = useContext(AuthContext);
  const [userData, setUserData] = useState(initialUserState);
  const [isOwner, setIsOwner] = useState(false);

  const selectedStyle = {
    color: " #1F9DC1",
  };

  const menuTitleStyle = {
    fontWeight: 700,
    fontFamily: "Roboto",
    color: "#6A6A6A",
  };

  const largeScreen = {
    fontSize: 15,
    marginLeft: 6,
  };

  const smallScreen = {
    fontSize: 10,
  };

  async function getUserData() {
    try {
      const refUser = await Firestore.collection("users")
        .doc(authUser.user.uid)
        .get();
      const userData = await refUser.data();
      setUserData({
        data: userData,
        isLoading: false,
      });

      const organizationRef = await Firestore.collection("accounts").where(
        "owner",
        "==",
        authUser.user.uid
      );
      const organizationData = await organizationRef.get();
      if (!!organizationData) {
        setIsOwner(true);
      }
    } catch (ex) {
      console.log("___ ex", ex);
    }
  }

  useEffect(() => {
    getUserData();
    //eslint-disable-next-line
  }, []);

  return (
    <List className={"mobile-header"}>
      <Link to="/" style={{ textDecoration: "none" }}>
        <ListItem button key="My Accounts">
          <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
            <DashboardIcon
              style={
                !pathname.includes("templates") &&
                !pathname.includes("profile") &&
                !pathname.includes("log") &&
                !pathname.includes("planLists") &&
                !pathname.includes("serviceLists") &&
                !pathname.includes("organization")
                  ? selectedStyle
                  : null
              }
              className="icon-size"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                style={{
                  color:
                    !pathname.includes("templates") &&
                    !pathname.includes("profile") &&
                    !pathname.includes("log") &&
                    !pathname.includes("planLists") &&
                    !pathname.includes("serviceLists") &&
                    !pathname.includes("organization_list") &&
                    !pathname.includes("marketplace") &&
                    !pathname.includes("organization")
                      ? "#1F9DC1"
                      : "#6a6a6a",
                  fontSize: 12,
                }}
              >
                My Accounts
              </Typography>
            }
          />
        </ListItem>
      </Link>
      <Link to={"/marketplace"} style={{ textDecoration: "none" }}>
        <ListItem button key="Users">
          <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
            <ShoppingCartIcon
              style={pathname.includes("marketplace") ? selectedStyle : null}
              className="icon-size"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                style={{
                  color: pathname.includes("marketplace")
                    ? "#1F9DC1"
                    : "#6a6a6a",
                  fontSize: 12,
                }}
              >
                Marketplace
              </Typography>
            }
          />
        </ListItem>
      </Link>
      {!userData.isLoading && isKWALLAdmin(userData.data?.role) && (
        <Link to={"/global-templates-list"} style={{ textDecoration: "none" }}>
          <ListItem button key="Users">
            <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
              <WebIcon
                style={pathname.includes("templates") ? selectedStyle : null}
                className="icon-size"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  style={{
                    color: pathname.includes("templates")
                      ? "#1F9DC1"
                      : "#6a6a6a",
                    fontSize: 12,
                  }}
                >
                  Templates
                </Typography>
              }
            />
          </ListItem>
        </Link>
      )}
      <Divider />
      {isDrawerOpen ? (
        <ListItem>
          <Box
            style={
              isDrawerOpen
                ? { ...largeScreen, ...menuTitleStyle }
                : { ...smallScreen, ...menuTitleStyle }
            }
          >
            USER
          </Box>
        </ListItem>
      ) : null}

      <Link to="/user/profile" style={{ textDecoration: "none" }}>
        <ListItem button key="Profile">
          <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
            <PeopleAltIcon
              style={pathname.includes("profile") ? selectedStyle : null}
              className="icon-size"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                style={{
                  color: pathname.includes("profile") ? "#1F9DC1" : "#6a6a6a",
                  fontSize: 12,
                }}
              >
                Profile
              </Typography>
            }
          />
        </ListItem>
      </Link>
      {isOwner ? (
        <Link to="/user/organization" style={{ textDecoration: "none" }}>
          <ListItem button key="Organization">
            <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
              <ApartmentIcon
                style={
                  pathname.includes("organization") &&
                  !pathname.includes("organization_list")
                    ? selectedStyle
                    : null
                }
                className="icon-size"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  style={{
                    color:
                      pathname.includes("organization") &&
                      !pathname.includes("organization_list") &&
                      !pathname.includes("switch-organization")
                        ? "#1F9DC1"
                        : "#6a6a6a",
                    fontSize: 12,
                  }}
                >
                  Organization
                </Typography>
              }
            />
          </ListItem>
        </Link>
      ) : null}
      <Link
        to="/organization/switch-organization"
        style={{ textDecoration: "none" }}
      >
        <ListItem button key="Switch Organization">
          <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
            <SyncIcon
              style={pathname.includes("switch") ? selectedStyle : null}
              className="icon-size"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                style={{
                  color: pathname.includes("switch") ? "#1F9DC1" : "#6a6a6a",
                  fontSize: 12,
                }}
              >
                Switch Organization
              </Typography>
            }
          />
        </ListItem>
      </Link>
      <Link to="/user/log" style={{ textDecoration: "none" }}>
        <ListItem button key="Activity Logs">
          <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
            <ListAltIcon
              style={pathname.includes("log") ? selectedStyle : null}
              className="icon-size"
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                style={{
                  color: pathname.includes("log") ? "#1F9DC1" : "#6a6a6a",
                  fontSize: 12,
                }}
              >
                Activity Logs
              </Typography>
            }
          />
        </ListItem>
      </Link>

      {!userData.isLoading && isKWALLAdmin(userData.data?.role) && (
        <>
          <Divider />
          {isDrawerOpen && (
            <ListItem>
              <Box
                style={
                  isDrawerOpen
                    ? { ...largeScreen, ...menuTitleStyle }
                    : { ...smallScreen, ...menuTitleStyle }
                }
              >
                KWALL ADMIN
              </Box>
            </ListItem>
          )}
          <Link to="/organization_list" style={{ textDecoration: "none" }}>
            <ListItem button key="Organizations">
              <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
                <ApartmentIcon
                  style={
                    pathname.includes("organization_list")
                      ? selectedStyle
                      : null
                  }
                  className="icon-size"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      color: pathname.includes("organization_list")
                        ? "#1F9DC1"
                        : "#6a6a6a",
                      fontSize: 12,
                    }}
                  >
                    Organizations
                  </Typography>
                }
              />
            </ListItem>
          </Link>
          <Link to="/planLists" style={{ textDecoration: "none" }}>
            <ListItem button key="PlanLists">
              <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
                <PaidIcon
                  style={pathname.includes("planLists") ? selectedStyle : null}
                  className="icon-size"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      color: pathname.includes("planLists")
                        ? "#1F9DC1"
                        : "#6a6a6a",
                      fontSize: 12,
                    }}
                  >
                    Manage Plans & Payment
                  </Typography>
                }
              />
            </ListItem>
          </Link>
          <Link to="/serviceLists" style={{ textDecoration: "none" }}>
            <ListItem button key="ServiceLists">
              <ListItemIcon style={{ minWidth: 48, marginLeft: 8 }}>
                <SupportAgentIcon
                  style={
                    pathname.includes("serviceLists") ? selectedStyle : null
                  }
                  className="icon-size"
                />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    style={{
                      color: pathname.includes("serviceLists")
                        ? "#1F9DC1"
                        : "#6a6a6a",
                      fontSize: 12,
                    }}
                  >
                    Manage Marketplace
                  </Typography>
                }
              />
            </ListItem>
          </Link>
        </>
      )}
    </List>
  );
};

export default AppMenu;
