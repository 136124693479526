import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  OutlinedInput,
  Checkbox,
  ListItemText,
  Grid,
  FormLabel,
  Hidden,
  Button,
  Paper,
} from "@mui/material";
import { useParams } from "react-router-dom";

import ThemeCard from "../../../../components/ThemeCard";
import { BreadcrumbContext } from "../../../../components/Breadcrumb";
import { Firestore } from "../../../../components/FirebaseAuth/firebase";
import { siteTypesArr, templateTypesArr } from "../../../../utils/constants";
import {
  LOCAL_STORAGE_CURRENT_ACCOUNT,
  getLocalStorage,
} from "../../../../utils/localStorage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const EditTemplatesFilters = () => {
  const listName = "sites";
  const title = "Edit Templates";
  const history = useHistory();
  const initialTemplateState = { isLoading: false, data: [] };
  const siteTypesReference = useRef();

  const { siteId } = useParams();
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const currentAccount = getLocalStorage(LOCAL_STORAGE_CURRENT_ACCOUNT);
  const [templatesList, setTemplateList] = useState(initialTemplateState);
  const [siteData, setSiteData] = useState({});
  const [selectedTemplates, setSelectedTemplates] = React.useState([]);
  const [templateMessage, setTemplateMessage] = useState("");
  const [selectedStiteTypes, setSelectedSiteTypes] = useState([]);
  const [finalSelectedTemplate, setFinalSelectedTemplate] = useState({});

  const titleCase = (str) => {
    let splitStr = str.toLowerCase().split(" ");
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  };

  async function fetchSingleSite() {
    const siteRef = Firestore.collection(
      `/accounts/${currentAccount.id}/sites`
    ).doc(siteId);

    const prevSiteData = await (await siteRef.get()).data();

    setSiteData({
      ...siteData,
      ...prevSiteData,
    });
  }

  function noTemplateFound() {
    setTemplateMessage("No Template Found.");
    setTemplateList(initialTemplateState);
    setSelectedTemplates([]);
  }

  async function fetchTemplatesList(currentSelectedTemplateTypes) {
    if (
      selectedStiteTypes.at(0) === "All" &&
      currentSelectedTemplateTypes === "All"
    ) {
      //  Template from Templates Collection
      const templateRef = Firestore.collection("templates");
      const snapshot = await templateRef
        .where("accountType", "==", currentAccount.accountType)
        .get();

      // Template from Templates Site
      const sitesTemplatesRef = Firestore.collection(
        `accounts/${currentAccount.id}/sites`
      );
      const siteTemplatesnapshot = await sitesTemplatesRef
        .where("isMyTemplate", "==", true)
        .get();

      if (sitesTemplatesRef.empty && snapshot.empty) {
        noTemplateFound();
        return;
      }

      const templateArr = [];
      snapshot.forEach((doc) => {
        const { id } = doc;
        templateArr.push({ id, ...doc.data() });
      });

      siteTemplatesnapshot.forEach((doc) => {
        console.log("___ DATA SITE", doc.id, doc.data());
        templateArr.push({
          id: doc.id,
          ...doc.data(),
          name: doc.data().siteName,
        });
      });

      setTemplateList({
        isLoading: false,
        data: templateArr,
      });
      setTemplateMessage("");
      return;
    }

    if (selectedStiteTypes.at(0) === "All") {
      if (currentSelectedTemplateTypes === "Default Template") {
        const templateRef = Firestore.collection("templates");
        const snapshot = await templateRef
          .where("accountType", "==", currentAccount.accountType)
          .get();

        if (snapshot.empty) {
          noTemplateFound();
          return;
        }

        const templateArr = [];
        snapshot.forEach((doc) => {
          const { id } = doc;
          templateArr.push({ id, ...doc.data() });
        });

        setTemplateList({
          isLoading: false,
          data: templateArr,
        });

        setTemplateMessage("");
        return;
      }

      if (currentSelectedTemplateTypes === "My Templates") {
        // Template from Templates Site
        const sitesTemplatesRef = Firestore.collection(
          `accounts/${currentAccount.id}/sites`
        );
        const siteTemplatesnapshot = await sitesTemplatesRef
          .where("isMyTemplate", "==", true)
          .get();

        if (siteTemplatesnapshot.empty) {
          noTemplateFound();
          return;
        }

        const templateArr = [];
        siteTemplatesnapshot.forEach((doc) => {
          templateArr.push({
            id: doc.id,
            ...doc.data(),
            name: doc.data().siteName,
          });
        });

        setTemplateList({
          isLoading: false,
          data: templateArr,
        });
        setTemplateMessage("");
        return;
      }
    }

    if (selectedStiteTypes.at(0) === "Drupal") {
      if (currentSelectedTemplateTypes === "All") {
        //  Template from Templates Collection
        const templateRef = Firestore.collection("templates");
        const snapshot = await templateRef
          .where("siteType", "==", selectedStiteTypes.at(0))
          .where("accountType", "==", currentAccount.accountType)
          .get();

        // Template from Templates Site
        const sitesTemplatesRef = Firestore.collection(
          `accounts/${currentAccount.id}/sites`
        );
        const siteTemplatesnapshot = await sitesTemplatesRef
          .where("siteType", "==", selectedStiteTypes.at(0))
          .where("isMyTemplate", "==", true)
          .get();

        if (sitesTemplatesRef.empty && snapshot.empty) {
          noTemplateFound();
          return;
        }

        const templateArr = [];
        snapshot.forEach((doc) => {
          const { id } = doc;
          templateArr.push({ id, ...doc.data() });
        });

        siteTemplatesnapshot.forEach((doc) => {
          templateArr.push({
            id: doc.id,
            ...doc.data(),
            name: doc.data().siteName,
          });
        });

        setTemplateList({
          isLoading: false,
          data: templateArr,
        });
        setTemplateMessage("");
        return;
      }
      if (currentSelectedTemplateTypes === "Default Template") {
        const templateRef = Firestore.collection("templates");
        const snapshot = await templateRef
          .where("siteType", "==", selectedStiteTypes.at(0))
          .where("accountType", "==", currentAccount.accountType)
          .get();

        if (snapshot.empty) {
          noTemplateFound();
          return;
        }

        const templateArr = [];
        snapshot.forEach((doc) => {
          const { id } = doc;
          templateArr.push({ id, ...doc.data() });
        });

        setTemplateList({
          isLoading: false,
          data: templateArr,
        });

        setTemplateMessage("");
        return;
      }

      if (currentSelectedTemplateTypes === "My Templates") {
        // Template from Templates Site
        const sitesTemplatesRef = Firestore.collection(
          `accounts/${currentAccount.id}/sites`
        );
        const siteTemplatesnapshot = await sitesTemplatesRef
          .where("siteType", "==", selectedStiteTypes.at(0))
          .where("isMyTemplate", "==", true)
          .get();

        if (siteTemplatesnapshot.empty) {
          noTemplateFound();
          return;
        }

        const templateArr = [];
        siteTemplatesnapshot.forEach((doc) => {
          templateArr.push({
            id: doc.id,
            ...doc.data(),
            name: doc.data().siteName,
          });
        });

        setTemplateList({
          isLoading: false,
          data: templateArr,
        });
        setTemplateMessage("");
        return;
      }
    }

    if (selectedStiteTypes.at(0) === "Wordpress") {
      if (currentSelectedTemplateTypes === "All") {
        //  Template from Templates Collection
        const templateRef = Firestore.collection("templates");
        const snapshot = await templateRef
          .where("siteType", "==", selectedStiteTypes.at(0))
          .where("accountType", "==", currentAccount.accountType)
          .get();

        // Template from Templates Site
        const sitesTemplatesRef = Firestore.collection(
          `accounts/${currentAccount.id}/sites`
        );
        const siteTemplatesnapshot = await sitesTemplatesRef
          .where("siteType", "==", selectedStiteTypes.at(0))
          .where("isMyTemplate", "==", true)
          .get();

        if (sitesTemplatesRef.empty && snapshot.empty) {
          noTemplateFound();
          return;
        }

        const templateArr = [];
        snapshot.forEach((doc) => {
          const { id } = doc;
          templateArr.push({ id, ...doc.data() });
        });

        siteTemplatesnapshot.forEach((doc) => {
          templateArr.push({
            id: doc.id,
            ...doc.data(),
            name: doc.data().siteName,
          });
        });

        setTemplateList({
          isLoading: false,
          data: templateArr,
        });
        setTemplateMessage("");
        return;
      }
      if (currentSelectedTemplateTypes === "Default Template") {
        const templateRef = Firestore.collection("templates");
        const snapshot = await templateRef
          .where("siteType", "==", selectedStiteTypes.at(0))
          .where("accountType", "==", currentAccount.accountType)
          .get();

        if (snapshot.empty) {
          noTemplateFound();
          return;
        }

        const templateArr = [];
        snapshot.forEach((doc) => {
          const { id } = doc;
          templateArr.push({ id, ...doc.data() });
        });

        setTemplateList({
          isLoading: false,
          data: templateArr,
        });

        setTemplateMessage("");
        return;
      }

      if (currentSelectedTemplateTypes === "My Templates") {
        // Template from Templates Site
        const sitesTemplatesRef = Firestore.collection(
          `accounts/${currentAccount.id}/sites`
        );
        const siteTemplatesnapshot = await sitesTemplatesRef
          .where("siteType", "==", selectedStiteTypes.at(0))
          .where("isMyTemplate", "==", true)
          .get();

        if (siteTemplatesnapshot.empty) {
          noTemplateFound();
          return;
        }

        const templateArr = [];
        siteTemplatesnapshot.forEach((doc) => {
          templateArr.push({
            id: doc.id,
            ...doc.data(),
            name: doc.data().siteName,
          });
        });

        setTemplateList({
          isLoading: false,
          data: templateArr,
        });
        setTemplateMessage("");
        return;
      }
    }
  }

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/sites`,
        text: currentAccount.name,
        active: false,
      },
      {
        to: `/account/${currentAccount.id}/${listName}`,
        text: titleCase(listName),
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
  }, [setBreadcrumb, title, listName, currentAccount.name, currentAccount.id]);

  useEffect(() => {
    fetchSingleSite();
    //eslint-disable-next-line
  }, [currentAccountType]);

  const handleChange = ({ target: { name, value } }) => {
    if (name === "selectedTemplates") {
      const { id } = value;
      setSelectedTemplates(typeof value === "string" ? value.split(",") : id);
      fetchTemplatesList(value);
      return;
    }
    if (name === "siteType") {
      setSelectedSiteTypes(
        typeof value === "string" ? value.split(",") : value
      );

      // SiteType Ref
      siteTypesReference.current = value;

      setSiteData({
        ...siteData,
        [name]: value,
      });

      return;
    }
    setSiteData({
      ...siteData,
      [name]: value,
    });
  };

  const hanldeSelectTemplate = (selectedTemplate) => {
    setFinalSelectedTemplate(selectedTemplate);
  };

  const handleSubmit = async (data) => {
    try {
      const finalObj = {
        ...siteData,
        createdAt: Date.now(),
        templateId: finalSelectedTemplate.isMyTemplate
          ? finalSelectedTemplate.templateId
          : finalSelectedTemplate.id,
        image: finalSelectedTemplate.image,
        siteType: finalSelectedTemplate.siteType,
      };

      let sitesCollectionRef = Firestore.collection(
        `/accounts/${currentAccount.id}/sites`
      ).doc(siteId);

      await sitesCollectionRef.set({
        ...finalObj,
      });

      if (!!Object.keys(finalSelectedTemplate).length) {
        const temp = await (
          await Firestore.collection("templates")
            .doc(
              finalSelectedTemplate.isMyTemplate
                ? finalSelectedTemplate.templateId
                : finalSelectedTemplate.id
            )
            .get()
        ).data();
        let accountsArr = [];
        if (!!temp.isSelected && !!temp.isSelected.length > 0) {
          accountsArr = Array.from(
            new Set([...temp.isSelected, currentAccount.id])
          );
          Firestore.collection("templates")
            .doc(finalSelectedTemplate.id)
            .set({
              ...temp,
              isSelected: accountsArr,
            });
          history.push(`/account/${currentAccount.id}/billing/plan/${siteId}`);
          return true;
        }
        accountsArr = Array.from(new Set([currentAccount.id]));
        Firestore.collection("templates")
          .doc(
            finalSelectedTemplate.isMyTemplate
              ? finalSelectedTemplate.templateId
              : finalSelectedTemplate.id
          )
          .set({
            ...temp,
            isSelected: accountsArr,
          });
        history.push(`/account/${currentAccount.id}/billing/plan/${siteId}`);
        return true;
      }
      history.push(`/account/${currentAccount.id}/billing/plan/${siteId}`);
      return true;
    } catch (ex) {
      console.log("___ ex", ex);
      return false;
    }
  };

  const handleCancel = () => {
    setSelectedTemplates([]);
    setSiteData({});
    history.push(`/account/${currentAccount.id}/sites`);
    return false;
  };

  return (
    <>
      <Grid container>
        <Paper style={{ width: "100%", padding: 20 }}>
          <Grid item xs={12}>
            <Hidden smDown>
              <Grid container>
                <Grid item xs={3} style={{ marginBottom: 20 }}>
                  <FormLabel component="legend">Site Template*</FormLabel>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: 20 }}></Grid>
                <Grid item xs={3} style={{ marginBottom: 20 }}>
                  <FormControl fullWidth>
                    <InputLabel component="legend">Site Types</InputLabel>
                    <Select
                      labelId="siteType"
                      name="siteType"
                      fullWidth
                      value={selectedStiteTypes}
                      onChange={handleChange}
                      input={<OutlinedInput label="Site Type" />}
                      renderValue={(selected) => selected}
                      MenuProps={MenuProps}
                    >
                      {siteTypesArr.map((temp) => (
                        <MenuItem
                          key={temp.id}
                          value={temp}
                          ref={siteTypesReference}
                        >
                          <Checkbox
                            checked={selectedStiteTypes.indexOf(temp) > -1}
                          />
                          <ListItemText primary={temp} />
                        </MenuItem>
                      ))}
                    </Select>
                    {!!templateMessage.length ? (
                      <FormHelperText style={{ color: "#0A79A6" }}>
                        {templateMessage}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
                {selectedStiteTypes.length > 0 && (
                  <Grid
                    item
                    xs={3}
                    style={{ marginLeft: 20, marginBottom: 10 }}
                  >
                    <FormControl fullWidth>
                      <InputLabel component="legend">Site Owner</InputLabel>
                      <Select
                        labelId="selectedTemplates"
                        name="selectedTemplates"
                        fullWidth
                        value={selectedTemplates}
                        onChange={handleChange}
                        input={<OutlinedInput label="Template Type" />}
                        renderValue={(selected) => selected}
                        MenuProps={MenuProps}
                      >
                        {templateTypesArr.map((temp) => (
                          <MenuItem key={temp.id} value={temp}>
                            <Checkbox
                              checked={selectedTemplates.indexOf(temp) > -1}
                            />
                            <ListItemText primary={temp} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={6}></Grid>
              </Grid>
              <Grid container style={{ marginBottom: 20 }}>
                {!templatesList.isLoading &&
                  templatesList.data.map((theme) => (
                    <Grid item xs={12} key={theme.id}>
                      <ThemeCard
                        finalSelectedTemplate={finalSelectedTemplate}
                        onSelectTemplate={hanldeSelectTemplate}
                        selectedTemplates={selectedTemplates}
                        theme={theme}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Hidden>
            <Hidden smUp>
              <Grid container style={{ marginBottom: 10 }}>
                <Grid item xs={12}>
                  <FormLabel component="legend">Site Template*</FormLabel>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="siteType">Site Types</InputLabel>
                    <Select
                      labelId="siteType"
                      id="siteType"
                      name="siteType"
                      multiple
                      value={selectedStiteTypes}
                      onChange={handleChange}
                      input={<OutlinedInput label="Site Types" />}
                      renderValue={(selected) => selected.join(", ")}
                      MenuProps={MenuProps}
                    >
                      {siteTypesArr.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox
                            checked={selectedStiteTypes.indexOf(name) > -1}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 20 }}>
                  <FormControl fullWidth>
                    <InputLabel component="legend">Templates</InputLabel>
                    <Select
                      labelId="selectedTemplates"
                      name="selectedTemplates"
                      fullWidth
                      value={selectedTemplates}
                      onChange={handleChange}
                      input={<OutlinedInput label="Template" />}
                      renderValue={(selected) => selected}
                      MenuProps={MenuProps}
                    >
                      {!templatesList.isLoading &&
                        templatesList.data.map((temp) => (
                          <MenuItem key={temp.id} value={temp}>
                            <Checkbox
                              checked={selectedTemplates.indexOf(temp.id) > -1}
                            />
                            <ListItemText primary={temp.name} />
                          </MenuItem>
                        ))}
                    </Select>
                    {!!templateMessage.length ? (
                      <FormHelperText style={{ color: "#0A79A6" }}>
                        {templateMessage}
                      </FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginBottom: 20, marginLeft: 10 }}
              >
                {!templatesList.isLoading &&
                  templatesList.data.map((theme) => (
                    <Grid
                      item
                      xs={12}
                      key={theme.id}
                      style={{ marginBottom: 10 }}
                    >
                      <ThemeCard
                        finalSelectedTemplate={finalSelectedTemplate}
                        onSelectTemplate={hanldeSelectTemplate}
                        selectedTemplates={selectedTemplates}
                        theme={theme}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Hidden>
            <Grid item style={{ matginTop: 50 }}>
              <Grid container spacing={3}>
                <Grid item>
                  <Button variant="contained" onClick={handleSubmit}>
                    Update
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    type="cancel"
                    variant="contained"
                    style={{ backgroundColor: "#F3F6F8", color: "#BD1B00" }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default EditTemplatesFilters;
