import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Button,
  Grid,
  IconButton,
  Hidden,
  Tooltip,
  Box,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";

import { BreadcrumbContext } from "../../../components/Breadcrumb";

import {
  FirebaseAuth,
  Firestore,
} from "../../../components/FirebaseAuth/firebase";
import Loader from "../../../components/Loader";
import AccountCard from "../../../components/AccountCard";
import { isKWALLAdmin } from "../../../utils/index";
import {
  LOCAL_STORAGE_CURRENT_ORG,
  LOCAL_STORAGE_CURRENT_USER,
  getLocalStorage,
} from "../../../utils/localStorage";

const Home = () => {
  const title = "My Collections";
  const history = useHistory();

  const currentOrganization = getLocalStorage(LOCAL_STORAGE_CURRENT_ORG);
  const userData = getLocalStorage(LOCAL_STORAGE_CURRENT_USER);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [loading, setLoading] = useState(true);
  const [accounts, setAccounts] = useState([]);

  const getAccounts = useCallback(async () => {
    setLoading(true);
    let records = [];
    const organizationRef = Firestore.collection("organizations").where(
      "access",
      "array-contains",
      FirebaseAuth.auth().currentUser.uid
    );

    const accountRef = FirebaseAuth.firestore()
      .collection("accounts")
      .where("accountActivation", "==", true)
      .where("access", "array-contains", FirebaseAuth.auth().currentUser.uid);

    const organizationData = await organizationRef.get();
    const accountData = await accountRef.get();

    if (accountData.empty && organizationData.empty) {
      history.push("/new-organization");
      return;
    }

    if (accountData.empty) {
      history.push("/new-account");
      return;
    }

    // For Admin, orgAdmin
    if (
      currentOrganization?.admins?.includes(FirebaseAuth.auth().currentUser.uid)
    ) {
      for (let accountId of currentOrganization.accounts) {
        if (accountId) {
          const accountRef = Firestore.collection("accounts").doc(accountId);
          const accountData = await accountRef.get();
          if (!!accountData.data()) {
            records.push({
              id: accountId,
              name: accountData.data().name,
              accountType: accountData.data().accountType,
              subscriptionStatus: accountData.data().subscriptionStatus,
            });
          }
        }
      }
    } else {
      accountData.forEach((account) => {
        if (
          (!currentOrganization ||
            currentOrganization?.accounts?.includes(account.id)) &&
          (!account.data().status || account.data().status !== "deleted")
        ) {
          records.push({
            id: account.id,
            name: account.data().name,
            accountType: account.data().accountType,
            subscriptionStatus: account.data().subscriptionStatus,
          });
        }
      });
    }
    setAccounts(records);
    setLoading(false);
  }, [currentOrganization, history]);

  useEffect(() => {
    setBreadcrumb([
      {
        to: "/",
        text: "Home",
        active: false,
      },
      {
        to: null,
        text: title,
        active: true,
      },
    ]);
    getAccounts();
    // eslint-disable-next-line
  }, [setBreadcrumb]);

  return (
    <>
      {loading ? (
        <Loader text="loading collections..."></Loader>
      ) : (
        <>
          {(isKWALLAdmin(userData?.role) ||
            currentOrganization?.admins?.includes(
              FirebaseAuth.auth().currentUser.uid
            )) && (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Hidden smDown>
                <Grid item>
                  <Button
                    fullWidth
                    onClick={() => history.push("/new-account")}
                    color="primary"
                    variant="contained"
                  >
                    <i className="fa fa-plus" style={{ marginRight: 7 }}></i>{" "}
                    CREATE NEW COLLECTION
                  </Button>
                </Grid>
              </Hidden>
              <Hidden smUp>
                <Grid item style={{ marginBottom: 10 }}>
                  <Tooltip title="Delete">
                    <IconButton>
                      <Button
                        size="small"
                        fullWidth
                        onClick={() => history.push("/new-account")}
                        color="primary"
                        variant="contained"
                      >
                        CREATE NEW COLLECTION
                      </Button>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Hidden>
            </Grid>
          )}
          <Grid container spacing={3} style={{ marginTop: 4 }}>
            {!!accounts.length ? (
              accounts.map((account, i) => (
                <AccountCard i={i} account={account} key={account?.id} />
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  marginTop: "10rem",
                }}
              >
                <Typography variant="h6">
                  There's no collections created yet. Create one to setup your
                  websites.
                </Typography>
              </Box>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

export default Home;
